<template>
  <Form
    title="Setup two-factor authentication"
    subtitle="Download the mobile app to scan the QR code to setup"
    @on-submit="submit"
  >
    <div class="instruction__container">
      <div class="instruction__item">
        <div class="counter">1</div>
        <div class="apps-row">
          <div class="app-item">
            <div class="app-item__image">
              <img src="../../../assets/googleMfa.png" />
            </div>
            <p>Google Authenticator</p>
          </div>
          <div class="app-item">
            <div class="app-item__image">
              <img src="../../../assets/microsoftMfa.png" />
            </div>
            <p>Microsoft Authenticator</p>
          </div>
          <div class="app-item">
            <div class="app-item__image">
              <img src="../../../assets/2fa.png" style="opacity: 0.3" />
            </div>
            <p>Or use any other app to scan QR-code</p>
          </div>
        </div>
      </div>
      <div class="instruction__item">
        <div class="counter">2</div>
        <canvas ref="qrCanvas" />
      </div>
      <div class="instruction__item">
        <div class="counter">3</div>
        <TextField
          v-model="totp"
          class="code-caption"
          name="totp"
          type="number"
          placeholder="Enter 6-digit code"
        />
      </div>
    </div>
    <AuthError />
    <SubmitButton :caption="'OK'" :is-loading="isLoading" />
  </Form>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Form from '../elements/Form.vue';
import SubmitButton from '../elements/SubmitButton.vue';
import QRCode from 'qrcode';
import { confirmSignIn, ConfirmSignInOutput } from 'aws-amplify/auth';
import AuthError from '../elements/AuthError.vue';
import TextField from '../elements/TextField.vue';

@Component({ components: { Form, AuthError, TextField, SubmitButton } })
export default class SetupTotp extends Vue {
  @Prop() totpUri!: string;
  totp: string = '';
  isLoading: boolean = false;

  get isValid() {
    return this.totp.length === 6;
  }

  @Watch('totp')
  onChange() {
    if (this.isValid) this.submit();
  }

  submit() {
    this.$store.commit('setLoginError', '');
    this.isLoading = true;
    confirmSignIn({
      challengeResponse: this.totp,
    })
      .then(this.onSuccess)
      .catch((err) => {
        if (err.message) {
          this.$store.commit('setLoginError', err.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Emit()
  onSuccess(output: ConfirmSignInOutput) {
    return output;
  }

  mounted() {
    const canvas = this.$refs.qrCanvas as HTMLElement;
    QRCode.toCanvas(canvas, this.totpUri, { width: 250, margin: 0 }, (error) => {
      if (error) console.error(error);
    });
  }
}
</script>

<style scoped lang="scss">
.instruction__container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.instruction__item {
  position: relative;
  padding: 24px 0;
  border: 1px solid #ebebeb;
  border-top: none;
  &:first-child {
    border-top: 1px solid #ebebeb;
  }
  display: flex;
  justify-content: center;
}

.counter {
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 50%;
  background: #000;
  height: 24px;
  width: 24px;
  color: #f6f6f6;
  text-align: center;
  font-family: 'JetBrains Mono';
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.apps-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
}

.app-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 100px;
  p {
    margin: 0;
    color: #0000004d;
    text-align: center;
    font-family: 'JetBrains Mono';
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: uppercase;
  }
}

.app-item__image {
  height: 42px;
  width: 42px;
  padding: 6.4px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
}

.code-caption::v-deep input {
  margin: 0;
  color: #000000;
  text-align: center;
  font-family: 'JetBrains Mono';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;

  &::placeholder {
    color: #0000004d;
  }

  &:focus {
    outline: none;
  }
}
</style>