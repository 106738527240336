<template>
  <form class="login-form" @submit.prevent @submit="onSubmit">
    <div v-if="title || subtitle" class="title-container">
      <h1 v-if="title" class="login-form__title">
        {{ title }}
      </h1>
      <h2 v-if="subtitle" class="login-form__subtitle">
        {{ subtitle }}
      </h2>
    </div>
    <slot></slot>
  </form>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class Form extends Vue {
  @Prop() title!: string | undefined;
  @Prop() subtitle!: string | undefined;

  @Emit()
  onSubmit() {}
}
</script>

<style lang="scss" scoped>
.login-form {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 480px;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.login-form__title {
  margin: 0;
  color: #000;
  font-family: Commissioner;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
}

.login-form__subtitle {
  margin: 0;
  color: #00000066;
  font-family: Commissioner;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-form__submit {
  flex: 1;
  display: flex;
  width: 400px;
  min-height: calc(21px + 24px * 2);
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  background: #000;
  cursor: pointer;
  border: none;
  box-shadow: none;
  outline: none;
  /* font */
  color: #fff;
  font-family: 'JetBrains Mono';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-transform: uppercase;
  &.loading,
  &:active {
    opacity: 0.7;
  }
}
</style>
