<template>
  <button class="logout-btn" @click="logout">Logout</button>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { signOut } from 'aws-amplify/auth';

@Component
export default class LogoutBtn extends Vue {
  logout() {
    this.$store.commit('setLoadedOnce', false);
    signOut().then(() => {
      this.$store.commit('setUserName', null);
      this.$store.commit('setAuthSession', undefined);

      this.$router.push('/Login/userlogin');
      this.$store.dispatch('cleanUpCompaniesInitialData');
    });
  }
}
</script>

<style scoped lang="scss">
button {
  border: 0;
  font-weight: 400;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  opacity: 0.3;
  color: currentColor;
  transition: var(--hover-opacity-transition);

  &:hover {
    opacity: 0.8;
  }
}
</style>
