<template>
  <div class="container">
    <router-link
      v-for="(item, index) in menuTabs"
      :key="index"
      class="navbtn"
      :class="{
        hidden: isHidden(item),
        disabled: item?.disabled,
      }"
      :to="{ name: item.name, params: { companyId: currentCompanyId } }"
    >
      <img v-if="item?.disabled" src="@/assets/LockSimple.svg" class="icon-lock" />{{
        item.meta?.menuTitle
      }}
    </router-link>
    <SidebarPopup>
      <template #button> {{ companyMenuTitle }} </template>
      <CompanyInfo :title="companyMenuTitle" />
    </SidebarPopup>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { companyRoutes as policyCompanyRoutes } from '@/router/customer-routes/routes';
import { RouteConfig } from 'vue-router';
import SidebarPopup from '@/components/SidebarPopup.vue';
import CompanyInfo from './CompanyInfo.vue';

@Component({ components: { SidebarPopup, CompanyInfo } })
export default class CompanyMenu extends Vue {
  get menuTabs(): Array<RouteConfig & { disabled?: boolean }> {
    const enabledRoutes = this.$store.getters.enabledRouteNames;
    return policyCompanyRoutes.map((route) => {
      const { name } = route;
      if (!enabledRoutes.find((enabledName: string) => enabledName === name)) {
        return { ...route, disabled: true };
      }
      return route;
    });
  }

  get companyMenuTitle() {
    return 'Company Information';
  }

  get currentCompanyId() {
    return this.$store.getters.companyInfo.companyID;
  }

  isHidden(item: RouteConfig) {
    return item?.meta?.tabVisibility === false;
  }
}
</script>

<style scoped lang="scss">
.container {
  font-family: Commissioner;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 0 var(--page-horizontal-padding);
  align-items: center;
  justify-content: space-between;
  height: 60px;
  flex: 0 0 60px;
  background: var(--app-background-color-light);
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.navbtn {
  margin: 0 32px 0 0;
  text-decoration: none;
  text-transform: none;
  color: var(--app-font-color-main);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  transition: var(--hover-opacity-transition);

  &:hover {
    opacity: 0.7;
  }

  &:last-of-type {
    margin-right: auto;
  }
}

.router-link-active {
  box-shadow: inset 0px -1px 0px var(--app-font-color-main);
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.hidden {
  display: none;
}

.icon-lock {
  margin-right: 4px;
}
</style>
