<template>
  <Form title="Login" @on-submit="submit">
    <AuthError />
    <TextField v-model="email" name="email" :label="'Email'" :placeholder="'enter email'" />
    <TextField
      v-model="password"
      name="password"
      type="password"
      :label="'Password'"
      :disabled="isLoading"
    />
    <RememberCheckBox />
    <SubmitButton caption="Login" :is-loading="isLoading" />
    <router-link to="/Login/forgot" class="link">Forgot Password?</router-link>
  </Form>
</template>

<script lang="ts">
import { signIn, SignInOutput } from 'aws-amplify/auth';
import { Component, Vue, Emit } from 'vue-property-decorator';
import Form from '../elements/Form.vue';
import RememberCheckBox from '../elements/RememberCheckBox.vue';
import SubmitButton from '../elements/SubmitButton.vue';
import TextField from '../elements/TextField.vue';
import AuthError from '../elements/AuthError.vue';

@Component({ components: { Form, TextField, RememberCheckBox, SubmitButton, AuthError } })
export default class Login extends Vue {
  email: string = '';
  password: string = '';
  isLoading: boolean = false;

  submit() {
    this.$store.commit('setLoginError', '');
    this.isLoading = true;
    signIn({
      username: this.email,
      password: this.password,
    })
      .then((res) => this.onSuccess({ output: res, email: this.email }))
      .catch((err) => {
        if (err.message) {
          this.$store.commit('setLoginError', err.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Emit()
  onSuccess(params: { output: SignInOutput; email: string }) {
    return params;
  }
}
</script>

<style lang="scss" scoped>
.link {
  margin: 0 auto;
  color: #000;
  text-align: center;
  font-family: Commissioner;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }
}
</style>
