<template>
  <div class="footer_customer">
    <div class="menu"></div>
    <div class="menu">
      <p class="item">© Intangic Services 2024</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  @Emit()
  toggleSidebar() {}
}
</script>

<style scoped lang="scss">
.footer_customer {
  box-sizing: border-box;
  width: 100%;
  justify-self: flex-end;
  padding: 32px 48px 47px;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
  margin-top: auto;
  border-top: 1px solid #0000001a;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu {
  display: flex;
  flex-direction: row;
  .item {
    margin: 0;
    &::after {
      content: '|';
      margin: 0 16px;
      height: 16px;
    }

    &:last-child::after {
      content: none;
    }
  }
  .item_click {
    cursor: pointer;
    user-select: none;
  }
}
</style>
