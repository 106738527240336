<template>
  <Form title="Confirm OTP" @on-submit="submit">
    <AuthError />
    <OtpInput v-model="totp" name="totp" :label="'6-digit code'" />
    <RememberCheckBox />
    <SubmitButton caption="Confirm" :is-loading="isLoading" :disabled="!isValid" />
  </Form>
</template>

<script lang="ts">
import { confirmSignIn, ConfirmSignInOutput } from 'aws-amplify/auth';
import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import AuthError from '../elements/AuthError.vue';
import Form from '../elements/Form.vue';
import RememberCheckBox from '../elements/RememberCheckBox.vue';
import SubmitButton from '../elements/SubmitButton.vue';
import OtpInput from '../elements/OtpInput.vue';

@Component({ components: { Form, OtpInput, RememberCheckBox, SubmitButton, AuthError } })
export default class ConfirmTotp extends Vue {
  totp: string = '';
  isLoading: boolean = false;

  get isValid() {
    return this.totp.length === 6;
  }

  @Watch('totp')
  onChange() {
    if (this.isValid) this.submit();
  }

  submit() {
    this.$store.commit('setLoginError', '');
    this.isLoading = true;
    confirmSignIn({
      challengeResponse: this.totp,
    })
      .then(this.onSuccess)
      .catch((err) => {
        if (err.message) {
          this.$store.commit('setLoginError', err.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Emit()
  onSuccess(output: ConfirmSignInOutput) {
    return output;
  }
}
</script>
