import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { api } from '../company';
import qs from 'qs';
import { queryCompanySubsidiaries } from '@/api-rosie/company/subsidiaries';

// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
const { apiGateWayUrl } = awsConfig;

@Module
class SubsidiariesModule extends VuexModule {
  private subsidiaries: any[] = [];
  private subsidiariesAreLoading: boolean = false;

  get companySubsidiaries() {
    return this.subsidiaries;
  }

  get companySubsidiariesAreLoading(): boolean {
    return this.subsidiariesAreLoading;
  }

  @Mutation
  public setCompanySubsidiaries(subsidiariesList: any[]) {
    this.subsidiaries = subsidiariesList;
  }

  @Mutation
  public setSubsidiariesLoading(value: boolean) {
    this.subsidiariesAreLoading = value;
  }

  @Action
  public async fetchSubsidiaries(companyName: string) {
    const q = qs.stringify({
      companyName: companyName,
    });
    const cognitoToken = store.getters.sessionToken;
    store.commit('setSubsidiariesLoading', true);
    return api({
      url: `${apiGateWayUrl}subsidiaries/?${q}`,
      method: 'get',
      headers: {
        Authorization: cognitoToken,
      },
    })
      .then(async (response) => {
        const result = response.data;
        if (result.success) {
          store.commit('setCompanySubsidiaries', result.data);
          return result.data;
        } else {
          throw new Error(`AWS  /subsidiaries success = false`);
        }
      })
      .catch(() => {
        store.commit('setCompanySubsidiaries', []);
        console.error(`load subsidiaries failed`);
      })
      .finally(() => {
        store.commit('setSubsidiariesLoading', false);
      });
  }

  @Action
  public async querySubsidiaries(id: string) {
    store.commit('setSubsidiariesLoading', true);
    return queryCompanySubsidiaries(id)
      .then((data) => {
        store.commit('setCompanySubsidiaries', data);
      })
      .catch(() => {
        store.commit('setCompanySubsidiaries', []);
        console.error(`load subsidiaries failed`);
      })
      .finally(() => {
        store.commit('setSubsidiariesLoading', false);
      });
  }

  // queryCompanySubsidiaries
}
export default SubsidiariesModule;
