<template>
  <div class="header">
    <div data-keep class="logo__container">
      <img class="logo__image" src="../../assets/logo-rosie.svg" />
      <p class="logo__text">rosie</p>
    </div>

    <autocomplete
      ref="searchInput"
      :search="search"
      :auto-select="autoselect"
      :debounce-time="250"
      :submit-on-enter="true"
      placeholder="Search a company"
      class="search"
      @submit="handleSubmit"
    >
    </autocomplete>
    <div class="user__container">
      <p v-if="username" class="username">{{ username }}</p>
      <LogoutBtn />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LogoutBtn from './LogoutBtn.vue';
import Menu from './HeaderMenu.vue';
import { Company } from '@/types';

@Component({
  components: {
    LogoutBtn,
    Menu,
  },
})
export default class AdminTopBar extends Vue {
  autoselect: boolean = true;

  search(input: string) {
    if (input.length < 1) {
      return [];
    }
    const companyNames: Array<string> = this.$store.getters.allCompanies.map(
      (c: Company) => c.companyName
    );
    const result = companyNames.filter((cmp) => {
      return cmp.toLowerCase().includes(input.toLowerCase());
    });
    if (result.length !== 0) return result;
    return [];
  }

  handleSubmit(result: string = (this.$refs.searchInput as HTMLInputElement)?.value) {
    const cmp: Company | undefined = this.$store.getters.allCompanies.find(
      (el: Company) => el.companyName == result
    );

    if (cmp) {
      this.$router.push({ path: `/Company/${cmp.companyID}/overview` });
    }
  }

  get username(): string {
    return this.$store.getters.username;
  }
}
</script>

<style scoped lang="scss">
@use '@/styles/header/header';

.header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: header.$height;
  background: #1f1f1f;
  padding: 0px 44px;
  justify-content: space-between;
  z-index: 3;
}

.logo__container {
  display: flex;
  align-items: center;
  flex: 2 2 auto;
}

.logo__image {
  color: #000;
  opacity: 0.3;
  margin-right: 10px;
}

.logo__text {
  font-family: Transcript Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20.6px;
  line-height: 110%;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.3;
}

.logo__title {
  font-family: 'Akkurat Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 110%;
  letter-spacing: -0.02em;
  max-width: 140px;
  margin: 0;
  margin-left: 10px;
  color: #000000;
  display: flex;
  align-items: center;
}

.user__container {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: #ffffff;

  // spacer
  *:not(:last-child):after {
    content: '|';
    margin: 0px 11px;
  }

  .username {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    opacity: 0.3;
  }
}

.search {
  position: relative;
  z-index: 1;
  margin: 0 22px 0 auto;
}

.search::v-deep div {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  opacity: 1;
  z-index: 3;
  color: #000000;

  input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px;
    width: 307px;
    height: 38px;
    background: #393939;
    border: 1px solid #464646;
    border-radius: 6px;
    outline: none;
    color: #ffffff;
  }
}
</style>
