import { render, staticRenderFns } from "./AdminTopBar.vue?vue&type=template&id=874915c2&scoped=true"
import script from "./AdminTopBar.vue?vue&type=script&lang=ts"
export * from "./AdminTopBar.vue?vue&type=script&lang=ts"
import style0 from "./AdminTopBar.vue?vue&type=style&index=0&id=874915c2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "874915c2",
  null
  
)

export default component.exports