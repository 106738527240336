import { store } from '../../index';

import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

import { Company } from '@/types';

import { queryCyFiStatsCurrent } from '../../../api-rosie/company';

import { convertHighlights } from './sector-peers';

const storage = window.localStorage;

@Module
class CustomGroup extends VuexModule {
  private groupIDList: string[] = [];
  private tempGroupIDList: string[] = [];
  private cyfiList: any[] = [];

  // getters
  get peersGroupTempList(): string[] {
    return this.tempGroupIDList;
  }

  get peersGroupCompany(): Company {
    return store.getters.companyInfo;
  }

  get cyfiTableData(): any[] {
    const result = this.cyfiList.map((el: any) => {
      return {
        ...el,
        highlights: el.highlights
          ? convertHighlights(el.highlights.data.Highlights, {
              symbol: el.highlights.data.General.CurrencySymbol,
              code: el.highlights.data.General.CurrencyCode,
            })
          : null,
      };
    });
    return result;
  }

  // actions and mutations

  @Mutation
  public setGroupIDList(idList: any[]) {
    this.groupIDList = idList;
    storage.setItem(store.getters.peersGroupCompany.companyID, JSON.stringify(this.groupIDList));
    store.commit('setTempGroupIDList', idList);
  }

  @Mutation
  public setTempGroupIDList(idList: any[]) {
    this.tempGroupIDList = idList;
  }

  @Action
  public initGroupIDList() {
    const storageList = storage.getItem(store.getters.peersGroupCompany.companyID);
    if (!storageList) {
      const newList = [store.getters.peersGroupCompany.companyID];
      store.commit('setGroupIDList', newList);
      return store.dispatch('fetchCyFiGroupList');
    }
    store.commit('setGroupIDList', JSON.parse(storageList));
    return store.dispatch('fetchCyFiGroupList');
  }

  @Mutation
  public setCyFiList(scores: any[]) {
    this.cyfiList = scores;
  }

  @Action
  public async fetchCyFiGroupList() {
    const idList = this.tempGroupIDList;
    return Promise.all(
      idList.map(async (id) => {
        const dataRow = await queryCyFiStatsCurrent(id)
          .then(async (data) => {
            const cmp: Company = store.getters.allCompanies.find((el: Company) => {
              return el.companyID == id;
            });
            const fiData = await store.dispatch('fetchSectorPeersFiDataRow', id);
            const result = {
              companyID: id,
              companyName: cmp.companyName,
              ...data[0],
              highlights: fiData,
            };

            return result;
          })
          .catch((err) => {
            console.error(err);
            return {};
          });
        return dataRow;
      })
    ).then((data) => {
      store.commit('setCyFiList', data);
    });
  }

  @Action
  public async load_CyFiStatsCurrent_FiData_byCompanyId(id: string) {
    return queryCyFiStatsCurrent(id).then((data) => {
      const cmp: Company = store.getters.allCompanies.find((el: Company) => el.companyID == id);
      return store.dispatch('fetchSectorPeersFiDataRow', id).then((fiData) => {
        if (!fiData) {
          console.warn('Cant load FiData for: ', cmp);
          return false;
        }
        return {
          companyID: id,
          companyName: cmp.companyName,
          ...data[0],
          highlights: fiData,
        };
      });
    });
  }

  @Mutation
  public appendCyFiList(newRow: any) {
    this.cyfiList.splice(this.cyfiList.length - 1, 0, newRow);
  }

  @Mutation
  public removeCyFiListById(id: string) {
    const ind = this.cyfiList.findIndex((row) => row.companyID === id);
    if (ind !== -1) {
      this.cyfiList.splice(ind, 1);
    }
  }
}
export default CustomGroup;
