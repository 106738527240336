<template>
  <div class="register-page__container">
    <Login v-if="status === 'signin'" @on-success="onSuccessLogin" />
    <SetupTotp
      v-else-if="status === 'setup_totp'"
      :totp-uri="totpUri"
      @on-success="onSuccessConfirm"
    />
    <ConfirmTotp v-else-if="status === 'confirm_totp'" @on-success="onSuccessConfirm" />
    <Preloader v-else />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SetupTotp from '../register/SetupTotp.vue';
import ConfirmTotp from './ConfirmTotp.vue';
import Login from './Login.vue';
import { ConfirmSignInOutput, type SignInOutput } from 'aws-amplify/auth';
import Preloader from '@/components/LoaderPage.vue';
// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
const { appName } = awsConfig;

@Component({ components: { Login, SetupTotp, ConfirmTotp, Preloader } })
export default class LoginPage extends Vue {
  status: 'signin' | 'setup_totp' | 'confirm_totp' | 'loading' = 'signin';
  totpUri: string = '';

  async onDone() {
    const redirectPath: string =
      this.$route.query.redirect && !Array.isArray(this.$route.query.redirect)
        ? this.$route.query.redirect
        : '/';
    this.status = 'loading';
    await this.$router.push(redirectPath);
    this.status = 'signin';
  }

  onSuccessLogin({ output, email }: { output: SignInOutput; email: string }) {
    switch (output.nextStep.signInStep) {
      case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP': {
        const uri = output.nextStep.totpSetupDetails.getSetupUri(appName, email).href;
        this.totpUri = uri;
        this.status = 'setup_totp';
        break;
      }

      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
        this.status = 'confirm_totp';
        break;

      case 'DONE':
        this.onDone();
    }
  }

  onSuccessConfirm({ nextStep }: ConfirmSignInOutput) {
    switch (nextStep.signInStep) {
      case 'DONE':
        this.onDone();
    }
  }
}
</script>

<style scoped lang="scss">
.register-page__container {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
