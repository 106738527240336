import { render, staticRenderFns } from "./LogoutBtn.vue?vue&type=template&id=21e040af&scoped=true"
import script from "./LogoutBtn.vue?vue&type=script&lang=ts"
export * from "./LogoutBtn.vue?vue&type=script&lang=ts"
import style0 from "./LogoutBtn.vue?vue&type=style&index=0&id=21e040af&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e040af",
  null
  
)

export default component.exports