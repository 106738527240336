<template>
  <div class="checkbox__container">
    <input id="remember" type="checkbox" :checked="remember" @input="updateRemember" />
    <label for="remember">Remember this device</label>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RememberCheckBox extends Vue {
  updateRemember(e: any) {
    this.$store.commit('setRemember', e.target.checked);
  }

  get remember(): boolean {
    return this.$store.getters.rememberOnLogin;
  }
}
</script>

<style lang="scss" scoped>
.checkbox__container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  label {
    cursor: pointer;
  }

  input + label {
    color: #000;
    font-family: Commissioner;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--app-font-color-main);

    margin: 0;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input + label::before {
    margin: 0 8px 4px 0;
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid var(--app-font-color-main);
    vertical-align: middle;
  }

  input:checked + label::before {
    border-color: #000;
    background: #000;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../../../assets/checkmark.svg);
  }
}
</style>
