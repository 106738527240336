<template>
  <div class="header">
    <div class="logo__container">
      <div class="logo__image" />
      <p class="logo__text">Intangic CyFi™ Platform</p>
    </div>

    <div v-if="username" class="user__container">
      <p class="username">{{ username }}</p>
      <LogoutBtn v-if="isRestrictedUser"></LogoutBtn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LogoutBtn from '@/components/header/LogoutBtn.vue';

@Component({
  components: {
    LogoutBtn,
  },
})
export default class Header extends Vue {
  get username(): string {
    if (this.isAdminUser) return this.$store.getters.companyFullName;

    return this.$store.getters.username;
  }

  get isRestrictedUser(): boolean {
    return this.$store.getters.isRestrictedUser;
  }

  get isAdminUser(): boolean {
    return this.$store.getters.isAdminUser;
  }
}
</script>

<style scoped lang="scss">
.header {
  font-family: Commissioner;
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 80px;
  min-height: 80px;
  background-color: var(--header-background-color);
  padding: 0px var(--page-horizontal-padding);
  justify-content: space-between;
}

.logo__container {
  display: flex;
  align-items: center;
  flex: 2 2 auto;
}

.logo__image {
  margin-right: 16px;
  width: 19px;
  height: 30px;
  background-image: var(--header-logo);
  background-position: cover;
  background-size: 19px 30px;
}

.logo__text {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  width: 120px;
  color: var(--header-logo-text-color);
}

.user__container {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  *:not(:last-child):after {
    content: '';
    margin: 0px 24px;
    width: 0px;
    height: 22px;
    opacity: 0.1;
    border-right: 1px solid var(--app-font-color-main);
  }

  .username {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }
}
</style>
