import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { Nullable } from '@/types';
import axios from 'axios';
// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
import { BreachProbabilityModule, BreachProbabilityRisk } from './breach-probability';
const { severityApiUrl } = awsConfig;

const RANGECOND_LOSS_SEVERITY = 'LossSeverity#current';
export const NPV_CALCULATOR_KEY_PREFIX = 'NpvCalculator';

export enum LossSeverityRiskGroup {
  'Low' = 'Low',
  'Medium' = 'Medium',
  'Medium-High' = 'Medium-High',
  'High' = 'High',
  'Highest' = 'Highest',
}

const riskBreachMap: Record<BreachProbabilityRisk, LossSeverityRiskGroup | null> = {
  [BreachProbabilityRisk.LowRiskProb]: LossSeverityRiskGroup['Low'],
  [BreachProbabilityRisk.LowMediumRiskProb]: null,
  [BreachProbabilityRisk.MediumRiskProb]: LossSeverityRiskGroup['Medium'],
  [BreachProbabilityRisk.MediumMediumHighRiskProb]: null,
  [BreachProbabilityRisk.MediumHighRiskProb]: LossSeverityRiskGroup['Medium-High'],
  [BreachProbabilityRisk.MediumHighHighRiskProb]: null,
  [BreachProbabilityRisk.HighRiskProb]: LossSeverityRiskGroup['High'],
  [BreachProbabilityRisk.HighHighestRiskProb]: null,
  [BreachProbabilityRisk.HighestRiskProb]: LossSeverityRiskGroup['Highest'],
};

export type LossCurrency = 'USD' | 'EUR';

type LossLegend = 'percent';

interface LossSeverityQueryResponse {
  RiskGroup?: LossSeverityRiskGroup;
  DataType?: string;
  LossSeverityPlot?: string;
  DataPoints?: string;
  LossSeverityAxisXCurrency?: LossCurrency;
  FrequencyAxisYLegend?: LossLegend;
  CurrentRisk?: 'TRUE' | 'FALSE';
  InsurableLossCoefficient?: string;
}

export enum LossSeverityUnit {
  bln = 'bln',
  mln = 'mln',
  k = 'k',
  none = 'none',
}

export const lossSeverityUnitOrder = [
  LossSeverityUnit.bln,
  LossSeverityUnit.mln,
  LossSeverityUnit.k,
  LossSeverityUnit.none,
];

export type LossSeverityPlotItem = {
  xUnit: LossSeverityUnit;
  x: number;
  xRaw: number;
  y: number;
  npv?: number;
  npvUnit?: LossSeverityUnit;
  ind?: number;
  initial: {
    x: number;
    xRaw: number;
  };
};

export enum LossTypes {
  insurable = 'insurable',
  economic = 'economic',
}

type LossSeverityPlotsDataList = { riskGroup: LossSeverityRiskGroup; value: string }[];

export type WeightedNPVItem = {
  WeightedNPV: number;
  WeightedNPVRaw: number;
  Unit: LossSeverityUnit;
};

export const getRawValueByUnit = (value: number, unit: LossSeverityUnit): number => {
  switch (unit) {
    case LossSeverityUnit.bln:
      return value * 1_000_000_000;
    case LossSeverityUnit.mln:
      return value * 1_000_000;
    case LossSeverityUnit.k:
      return value * 1_000;
    default:
      return value;
  }
};

const parseLossSeverityPlotPoints = (
  item: string,
  coefficient?: number
): LossSeverityPlotItem[] => {
  let arr: LossSeverityPlotItem[] = [];
  try {
    const cleanStr = item.replace(/""/g, '"');
    const obj = JSON.parse(cleanStr);
    if (Array.isArray(obj)) {
      arr = [...obj];
    } else if (Object.prototype.hasOwnProperty.call(obj, 'DataPoints')) {
      arr = [...obj.DataPoints];
    }
  } catch (e) {
    console.error(e);
  }
  return arr.map((el, ind) => {
    const initial = {
      x: el.x,
      xRaw: el.xRaw,
    };
    if (coefficient) {
      el.x = el.x * coefficient;
      if (el.npv) el.npv = el.npv * coefficient;
    }
    el.xRaw = getRawValueByUnit(el.x, el.xUnit);
    if (el.xRaw === el.x) {
      el.xUnit = LossSeverityUnit.none;
    }
    return { ...el, ind, initial };
  });
};

function getWeightedNPVItem(value: number, unit?: LossSeverityUnit): WeightedNPVItem {
  return {
    WeightedNPV: value,
    WeightedNPVRaw: getRawValueByUnit(value, unit || LossSeverityModule.unit),
    Unit: unit || LossSeverityModule.unit,
  };
}

function getCoef(max: Nullable<number>, economic: number): number {
  return max ? max / economic : 1;
}

const storage = window.localStorage;

@Module({ dynamic: true, store, name: 'lossSeverity' })
class LossSeverity extends VuexModule {
  private plotsData: LossSeverityPlotsDataList = [];
  private riskGroup: Nullable<LossSeverityRiskGroup> = null;
  private weightedNpv: {
    id: string;
    data: Partial<Record<LossSeverityRiskGroup, WeightedNPVItem>>;
  }[] = [];
  private lossCoefficient: number = 1;
  private insurableLossCoefficient: number = 0.2;
  private lossCurrency: LossCurrency = 'USD';
  private lossYLegend: LossLegend = 'percent';
  private npvCalculatorTable: {
    attachment: WeightedNPVItem;
    limit: WeightedNPVItem;
    maxInsurableLoss: Nullable<WeightedNPVItem>;
    npv: WeightedNPVItem;
  }[] = [];

  @Action({ commit: 'setLossSeverityPlots' })
  public fetchLossSeverityPlots() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_LOSS_SEVERITY)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND_LOSS_SEVERITY + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  @Mutation
  public setLossSeverityPlots(data: LossSeverityQueryResponse[]) {
    if (Array.isArray(data)) {
      const plotsDataList = data.reduce((pre, cur) => {
        const plotField = cur.LossSeverityPlot || cur.DataPoints;
        if (cur.RiskGroup && plotField && cur.DataType?.includes('#current#')) {
          pre.push({ riskGroup: cur.RiskGroup, value: plotField });
          this.insurableLossCoefficient = cur.InsurableLossCoefficient
            ? Number(cur.InsurableLossCoefficient)
            : 0.2;
          if (cur.CurrentRisk === 'TRUE') this.riskGroup = cur.RiskGroup;
          this.lossCurrency = cur.LossSeverityAxisXCurrency ?? this.currency;
          this.lossYLegend = cur.FrequencyAxisYLegend ?? this.lossYLegend;
        }
        return pre;
      }, [] as LossSeverityPlotsDataList);
      this.plotsData.splice(0, this.plotsData.length, ...plotsDataList);
    } else {
      this.riskGroup = null;
      this.plotsData.splice(0, this.plotsData.length);
    }

    //set risk
    if (BreachProbabilityModule.breachCurrentData && !this.riskGroup) {
      this.riskGroup = null;
      const res = Object.entries(BreachProbabilityModule.breachCurrentData.scheme).find(
        ([, value]) => {
          return BreachProbabilityModule.breachCurrentData?.BreachProbability === value;
        }
      );
      if (res) {
        this.riskGroup = riskBreachMap[res[0] as BreachProbabilityRisk];
      }
    }
  }

  @Mutation
  public setLossCoefficient(value: number) {
    this.lossCoefficient = value;
  }

  get coefficient() {
    return this.lossCoefficient;
  }

  get defaultInsurableLossCoefficient() {
    return this.insurableLossCoefficient;
  }

  get plotsInitial() {
    return this.plotsData.reduce(
      (pre, cur) => {
        pre[cur.riskGroup] = parseLossSeverityPlotPoints(cur.value);
        return pre;
      },
      {} as Partial<Record<LossSeverityRiskGroup, LossSeverityPlotItem[]>>
    );
  }

  get plots() {
    return this.plotsData.reduce(
      (pre, cur) => {
        pre[cur.riskGroup] = parseLossSeverityPlotPoints(cur.value, this.coefficient);
        return pre;
      },
      {} as Partial<Record<LossSeverityRiskGroup, LossSeverityPlotItem[]>>
    );
  }

  get plotsWithNPV() {
    return this.plotsData.reduce(
      (pre, cur) => {
        pre[cur.riskGroup] = parseLossSeverityPlotPoints(cur.value, this.coefficient).filter(
          (el) => el.npv !== undefined
        );
        return pre;
      },
      {} as Partial<Record<LossSeverityRiskGroup, LossSeverityPlotItem[]>>
    );
  }

  get plotsEconomic() {
    return this.plotsData.reduce(
      (pre, cur) => {
        pre[cur.riskGroup] = parseLossSeverityPlotPoints(cur.value);
        return pre;
      },
      {} as Partial<Record<LossSeverityRiskGroup, LossSeverityPlotItem[]>>
    );
  }

  get currentInitialPlot() {
    return this.plotsInitial[this.currentRiskGroup];
  }

  get currentPlot() {
    return this.plots[this.currentRiskGroup];
  }

  get currentPlotWithNPV() {
    return this.plotsWithNPV[this.currentRiskGroup];
  }

  get currentRiskGroup() {
    return this.riskGroup || LossSeverityRiskGroup.Low;
  }

  get startRangeInds() {
    if (!this.currentPlotWithNPV) return [0, 0];
    return [
      this.currentPlotWithNPV[0].ind || 0,
      this.currentPlotWithNPV[this.currentPlotWithNPV.length - 1].ind || 0,
    ];
  }

  get currency() {
    return this.lossCurrency;
  }

  get unit() {
    if (!this.currentPlot) return LossSeverityUnit.mln;
    return this.currentPlot[0].xUnit;
  }

  get maxEconomicLoss() {
    const plot = LossSeverityModule.plotsEconomic[LossSeverityModule.currentRiskGroup];
    return plot && plot[plot.length - 1];
  }

  @Action
  public async fetchWeightedNpv({
    severityFrom,
    severityTo,
    maxInsurableLoss,
    id,
    scenario = '',
  }: {
    severityFrom: number;
    severityTo: number;
    maxInsurableLoss?: Nullable<number>;
    id?: string;
    scenario?: string;
  }) {
    if (id && this.weightedNpv.find((el) => el.id === id)) return;
    const cognitoToken = store.getters.sessionToken;
    const companyId = store.getters.companyId;

    const coef = getCoef(
      maxInsurableLoss ? getWeightedNPVItem(maxInsurableLoss).WeightedNPVRaw : null,
      this.maxEconomicLoss?.xRaw || 1
    );
    return axios({
      url: `${severityApiUrl}weightedNpv`,
      method: 'post',
      headers: {
        Authorization: cognitoToken,
      },
      data: {
        companyID: companyId,
        severityFrom: maxInsurableLoss ? severityFrom / coef : severityFrom,
        severityTo: maxInsurableLoss ? severityTo / coef : severityTo,
        maxInsurableLoss, // TODO ask correct field
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          if (maxInsurableLoss !== undefined) {
            this.setNpvCalculatorData({
              severityFrom,
              severityTo,
              maxInsurableLoss,
              coef,
              data: response.data,
              scenario,
            });
            return;
          }
          store.commit('setWeightedNpv', {
            id: id || 'empty_id',
            data: response.data.weightedNpv,
          });
          return response.data;
        }
        throw new Error();
      })
      .catch(() => {
        console.error(`load weightedNpv data failed`);
        if (maxInsurableLoss) return;
        store.commit('setWeightedNpv', { id: id || 'empty_id', data: [] });
      });
  }

  @Mutation
  public setWeightedNpv({ id, data }: { id: string; data: any[] }) {
    const res = data.reduce((pre, cur) => {
      const item: WeightedNPVItem = {
        WeightedNPV: cur.Weighted_NPV,
        WeightedNPVRaw: getRawValueByUnit(cur.Weighted_NPV, cur.Unit),
        Unit: cur.Unit,
      };
      pre[cur.RiskGroup] = item;
      return pre;
    }, {});
    this.weightedNpv.splice(this.weightedNpv.length - 1, 0, { id, data: res });
  }

  @Mutation
  public setNpvCalculatorData({
    severityFrom,
    severityTo,
    maxInsurableLoss,
    coef,
    data,
    scenario,
  }: {
    severityFrom: number;
    severityTo: number;
    maxInsurableLoss: Nullable<number>;
    coef: number;
    data: any;
    scenario: string;
  }) {
    const attachment = getWeightedNPVItem(severityFrom);
    const limit = getWeightedNPVItem(severityTo - severityFrom);
    const _maxInsurableLoss = maxInsurableLoss ? getWeightedNPVItem(maxInsurableLoss) : null;
    const npvData = data.weightedNpv.find(
      (el: { RiskGroup: LossSeverityRiskGroup }) =>
        el.RiskGroup === LossSeverityModule.currentRiskGroup
    );
    const npv = npvData
      ? getWeightedNPVItem(npvData.Weighted_NPV * coef, npvData.Unit)
      : getWeightedNPVItem(0);

    this.npvCalculatorTable.splice(0, 0, {
      attachment,
      limit,
      maxInsurableLoss: _maxInsurableLoss,
      npv: npv,
    });

    // const storageKey = getStorageKey(scenario);

    const storageData = JSON.stringify(this.npvCalculatorTable);
    storage.setItem(scenario, storageData);
  }

  @Mutation
  public setNpvCalculatorInitial(scenario: string) {
    const storageData = storage.getItem(scenario);
    // update table with the stored value
    this.npvCalculatorTable.splice(
      0,
      this.npvCalculatorTable.length,
      ...((storageData && JSON.parse(storageData)) || [])
    );
  }

  @Mutation
  public clearNpvCalculatorStorage(scenario: string) {
    storage.removeItem(scenario);
    this.npvCalculatorTable.splice(0, this.npvCalculatorTable.length);
  }

  get weightedNpvMap() {
    return this.weightedNpv;
  }

  get npvCalculatorTableData() {
    return this.npvCalculatorTable;
  }
}

export const LossSeverityModule = getModule(LossSeverity);
