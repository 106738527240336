<template>
  <div class="alert__container">
    <img
      class="icon"
      src="../assets/alert-svg-icon.svg"
      :class="{
        high: data.RaeRisk === 'High',
        medium: data.RaeRisk === 'Medium',
        low: data.RaeRisk === 'Low',
      }"
    />
    <div
      class="text__container"
      :class="{
        high: data.RaeRisk === 'High',
        medium: data.RaeRisk === 'Medium',
        low: data.RaeRisk === 'Low',
      }"
    >
      <p class="message">Alert: Dropped to {{ data.RaeRisk }} Risk</p>
      <p class="date-link">
        <span>{{ new Date(data.timeStamp).toLocaleDateString('eu') }}</span>
        <router-link :to="{ name: 'CompanyCyData', params: { companyId: routerCompanyId } }">
          Learn more
        </router-link>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CyDataWithAlerts } from '@/store/modules/company-tabs/cydata';

@Component
export default class Alert extends Vue {
  @Prop() data!: CyDataWithAlerts;

  get routerCompanyId(): string {
    return this.$route.params.companyId;
  }
}
</script>

<style scoped lang="scss">
.alert__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.icon {
  margin-right: 6px;
  &.high {
    filter: invert(34%) sepia(18%) saturate(7169%) hue-rotate(342deg) brightness(89%) contrast(91%);
  }
  &.medium {
    filter: invert(81%) sepia(9%) saturate(6253%) hue-rotate(342deg) brightness(80%) contrast(95%);
  }
  &.low {
    filter: invert(48%) sepia(11%) saturate(2038%) hue-rotate(80deg) brightness(92%) contrast(86%);
  }
}

.text__container {
  font-family: 'Inter';
  font-style: normal;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.high {
  color: #d93f30;
}
.medium {
  color: #c88d1c;
}
.low {
  color: #3d894c;
}

.message {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.date-link {
  margin: 0;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  span {
    opacity: 0.8;
    margin-right: 9px;
  }
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
</style>