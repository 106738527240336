import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { api } from '../company';
import qs from 'qs';
import { queryCompanyScaledCyber } from '../../../api-rosie/company';

// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
const { apiGateWayUrl } = awsConfig;

export interface EodApiDateItem {
  date: string;
  open: number;
  high: number;
  low: number;
  close: number;
  adjusted_close: number;
  volume: number;
}

@Module
class ChartsData extends VuexModule {
  private stockPriceData: any[] = [];
  private cyberValuesData: any[] = [];

  get stockPriceDataChart() {
    return this.stockPriceData;
  }

  get overview_CyberValuesData() {
    return this.cyberValuesData;
  }

  @Mutation
  public setStockPriceData(data: any[]) {
    this.stockPriceData = data;
  }

  @Action
  public async fetchStockPrice(companyID: string) {
    try {
      const codeExchage = store.getters.companyEod;

      if (!codeExchage) {
        console.warn('EODlist has no data for: ', companyID);
        throw new Error('cant make fetchStockPrice request without codeExchage');
      }

      const timeNow = new Date();
      const timeArr = timeNow
        .toLocaleDateString('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
        .split('/');
      const timeStr = [timeArr[2], timeArr[0], timeArr[1]].join('-');

      const q = qs.stringify({
        codeExchange: `${codeExchage.code}.${codeExchage.exchange}`,
        from: '2017-01-05',
        to: timeStr,
        period: 'm',
      });

      const cognitoToken = store.getters.sessionToken;

      return api({
        url: `${apiGateWayUrl}fiData/stockPrice/?${q}`,
        method: 'get',
        headers: {
          Authorization: cognitoToken,
        },
      })
        .then(async (response) => {
          const result = response.data;
          if (result.success) {
            store.commit('setStockPriceData', result.data);
          } else {
            throw new Error(`AWS  /fiData/stockPrice success = false`);
          }
        })
        .catch(() => {
          console.error(`load stockPriceData failed`);
          store.commit('setStockPriceData', []);
        });
    } catch (err) {
      // @ts-ignore
      console.error(err.message);
      store.commit('setStockPriceData', []);
    }
  }

  @Action
  public async retryfetchStockPrice(companyID: string) {
    try {
      const codeExchage = store.getters.companyEod;

      if (!codeExchage) {
        console.warn('EODlist has no data for: ', companyID);
        throw new Error('cant make req without codeExchage');
      }

      const timeNow = new Date();
      const timeArr = timeNow
        .toLocaleDateString('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
        .split('/');
      const timeStr = [timeArr[2], timeArr[0], timeArr[1]].join('-');
      console.warn(timeStr);

      const q = qs.stringify({
        codeExchange: `${codeExchage.code}`,
        from: '2017-01-05',
        to: timeStr,
        period: 'm',
      });

      const cognitoToken = store.getters.sessionToken;

      return api({
        url: `${apiGateWayUrl}fiData/stockPrice/?${q}`,
        method: 'get',
        headers: {
          Authorization: cognitoToken,
        },
      })
        .then(async (response) => {
          const result = response.data;
          if (result.success) {
            store.commit('setStockPriceData', result.data);
          } else {
            throw new Error(`AWS  /fiData/stockPrice success = false`);
          }
        })
        .catch(() => {
          console.error(`load stockPriceData failed`);
          store.commit('setStockPriceData', []);
        });
    } catch {
      store.commit('setStockPriceData', []);
    }
  }

  @Mutation
  public setCyberValuesData(data: any[]) {
    this.cyberValuesData = data;
  }

  @Action
  public async loadCyberValuesData(id: string) {
    return queryCompanyScaledCyber(id)
      .then((data) => {
        store.commit('setCyberValuesData', data);
      })
      .catch(() => {
        console.error(`load cyberValuesData failed`);
        store.commit('setCyberValuesData', []);
      });
  }

  @Action
  public async fetchDailyStockPrice({ from, to }: { from: Date; to: Date }) {
    try {
      const companyId = store.getters.companyId;
      const codeExchage = store.getters.companyEod;

      if (!codeExchage) {
        console.warn('EODlist has no data for: ', companyId);
        throw new Error('cant make fetchStockPrice request without codeExchage');
      }

      const q = qs.stringify({
        codeExchange: 'AAPL.US', // `${codeExchage.code}.${codeExchage.exchange}`,
        from: from.toLocaleDateString('sv', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        to: to.toLocaleDateString('sv', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        period: 'd',
      });

      const cognitoToken = store.getters.sessionToken;

      return api({
        url: `${apiGateWayUrl}fiData/stockPrice/?${q}`,
        method: 'get',
        headers: {
          Authorization: cognitoToken,
        },
      })
        .then((response) => {
          const result = response.data;
          if (result.success) {
            const { data } = result;
            return data as Array<EodApiDateItem>;
          } else {
            throw new Error(`AWS  /fiData/stockPrice success = false`);
          }
        })
        .catch(() => {
          console.error(`load stockPriceData failed`);
        });
    } catch (err) {
      console.error(err);
    }
  }
}
export default ChartsData;
