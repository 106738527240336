import { store } from './index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

const getAdminRequestArr = () => {
  return [
    store.dispatch('fetchExchangeData').then(() => store.commit('incrementProgress')),
    store.dispatch('initCustomizeTableOptions').then(() => store.commit('incrementProgress')),
    store.dispatch('loadCompanies').then(() => {
      return store.dispatch('loadViewedList').then(() => {
        store.commit('incrementProgress');
      });
    }),
  ];
};

const getCustomerRequestArr = () => {
  return [
    store.dispatch('fetchExchangeData').then(() => store.commit('incrementProgress')),
    store.dispatch('initCustomizeTableOptions').then(() => store.commit('incrementProgress')),
    // store.dispatch('loadCompanies').then(() => store.commit('incrementProgress')),
  ];
};

@Module
class InitialLoadings extends VuexModule {
  private loadedOnce: boolean = false;
  private waitingForResponse: boolean = false;
  private progressBar = {
    count: 4,
    loaded: 0,
  };

  get initialDataProgress() {
    return this.progressBar;
  }

  get initialDataIsLoaded() {
    return this.loadedOnce;
  }

  @Mutation
  public setLoadedOnce(result: boolean) {
    this.loadedOnce = result;
    if (!this.loadedOnce) {
      this.progressBar.loaded = 0;
    }
  }

  @Mutation
  public setWaitingForResponse(result: boolean) {
    this.waitingForResponse = result;
  }

  @Action
  public async loadInitialData(userType: 'admin' | 'customer') {
    if (this.loadedOnce || this.waitingForResponse) return true;

    const promises = userType === 'admin' ? getAdminRequestArr() : getCustomerRequestArr();

    store.commit('setProgressBarCount', promises.length);
    store.commit('setWaitingForResponse', true);

    return Promise.allSettled(promises).then(() => {
      store.commit('setWaitingForResponse', false);
      setTimeout(() => {
        store.commit('setLoadedOnce', true);
      }, 300);
    });
  }

  @Mutation
  public setProgressBarCount(count: number) {
    this.progressBar.count = count;
  }

  @Mutation
  public incrementProgress() {
    this.progressBar.loaded = this.progressBar.loaded + 1;
  }
}
export default InitialLoadings;
