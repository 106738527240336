<template>
  <button
    type="submit"
    class="login-form__submit"
    :class="{ loading: isLoading, disabled }"
    :disabled="disabled || isLoading"
  >
    <template v-if="!isLoading">{{ caption }}</template>
    <InsideBtnSpinner v-else :scale="0.4" />
  </button>
</template>

<script lang="ts">
import InsideBtnSpinner from '@/components/InsideBtnSpinner.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: { InsideBtnSpinner } })
export default class SubmitButton extends Vue {
  @Prop() caption!: string;
  @Prop() isLoading!: boolean;
  @Prop() disabled!: boolean;
}
</script>

<style lang="scss" scoped>
.login-form__submit {
  flex: 1;
  display: flex;
  width: 100%;
  min-height: calc(21px + 24px * 2);
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  background: #000;
  cursor: pointer;
  border: none;
  box-shadow: none;
  outline: none;
  /* font */
  color: #fff;
  font-family: 'JetBrains Mono';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-transform: uppercase;
  &.loading,
  &:active {
    opacity: 0.7;
  }
}

.disabled {
  background: #B2B2B2;
}
</style>
