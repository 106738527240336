<template>
  <div class="otp-field__container">
    <label v-if="label" :for="name">
      {{ label }}
    </label>
    <input
      :id="name"
      :value="value"
      :name="name"
      autocomplete="off"
      autofocus
      type="number"
      @input="onInput"
    />
    <div class="display-value">
      <template v-for="(item, ind) in displayArr">
        <p v-if="item" :key="ind" class="item number">{{ item }}</p>
        <div v-else :key="ind + 'empty'" class="item empty" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator';

@Component
export default class OtpInput extends Vue {
  @Prop() name!: string;
  @Prop() label!: string;
  @ModelSync('change', 'valueName', { type: String }) value!: string;

  onInput(event: InputEvent) {
    if (event.inputType === 'deleteContentBackward') {
      this.value = this.value.slice(0, -1);
      return;
    }
    if (event.inputType !== 'insertText') return;
    if (!event.data) return;
    if (this.value.length >= 6) return;
    const numberRegex = /^\d+$/;
    if (!numberRegex.test(event.data)) return;
    this.value = this.value + event.data;
  }

  get displayArr() {
    const arr = [null, null, null, null, null, null];
    return arr.map((el, ind) => {
      return this.value[ind] || null;
    });
  }
}
</script>

<style lang="scss" scoped>
.otp-field__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  position: relative;
}

label {
  color: #000;
  font-family: Commissioner;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

input,
.display-value {
  height: calc(21px + 16px * 2);
  width: 100%;
}

input {
  opacity: 0;
  position: relative;
  z-index: 1;
}

input:focus + .display-value {
  border: 1px solid rgba(0, 0, 0, 1);
}

.display-value {
  position: absolute;
  bottom: 0;
  z-index: 0;
  display: flex;
  padding: 16px;
  color: #000;
  font-family: 'JetBrains Mono';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.item:nth-child(3) {
  margin-right: 20px;
}

.empty,
.number {
  width: 10px;
  height: 100%;
  text-align: center;
  margin: 0 10px 0 0;
  position: relative;
}

.empty {
  border-bottom: solid 2px #b2b2b2;
}

input:focus + .display-value div:first-of-type::before {
  content: '';
  position: absolute;
  top: -1px;
  height: calc(100% + 6px);
  left: -6px;
  width: 1px;
  background: transparent;
  animation: PulseAttention 0.8s ease-out forwards infinite;
}

input:focus + .display-value p:nth-child(6)::before {
  content: '';
  position: absolute;
  top: -1px;
  height: calc(100% + 4px);
  right: -6px;
  width: 1px;
  background: transparent;
  animation: PulseAttention 0.8s ease-out forwards infinite;
}

@keyframes PulseAttention {
  50% {
    background: #000;
  }
}
</style>
