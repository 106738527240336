<template>
  <div class="register-page__container">
    <Form title="Password recovery" @on-submit="submit">
      <AuthError />
      <p v-if="loginError || successText" class="message">
        <template v-if="loginError">
          Please try again later or contact our support team <span>support@intangic.com</span> for further assistance.
        </template>
        <template v-else>
          {{ successText }}
        </template>
      </p>
      <template v-else>
        <TextField 
          v-model="email"
          name="email"
          :label="'Email'"
          :placeholder="'enter email'"
        />
        <SubmitButton caption="Restore password" :is-loading="isLoading" />
      </template>
      <router-link to="/Login/userlogin" class="link">Sign in</router-link>
    </Form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AuthError from '../elements/AuthError.vue';

import Form from '../elements/Form.vue';
import SubmitButton from '../elements/SubmitButton.vue';
import TextField from '../elements/TextField.vue';

@Component({ components: { Form, AuthError, TextField, SubmitButton } })
export default class ForgotPasswordPage extends Vue {
  email: string = '';
  isLoading: boolean = false;
  successText = '';

  mounted() {
    this.$store.commit('setLoginError', '');
  }

  onSucces() {
    this.successText = 'Your password recovery request has been successfully received';
  }

  onError() {
    this.$store.commit('setLoginError', 'something went wrong');
  }

  submit() {
    this.$store.commit('setLoginError', '');
    this.isLoading = true;
    this.$store.dispatch('fetchRestorePassword', this.email)
      .then(this.onSucces)
      .catch(this.onError)
      .finally(() => {
        this.isLoading = false;
      });
  }

  get loginError(): string {
    return this.$store.getters.sessionLoginError;
  }
}
</script>

<style scoped lang="scss">
.register-page__container {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  margin: 0 auto;
  color: #000;
  text-align: center;
  font-family: Commissioner;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }
}

.message {
  text-align: center;
  margin: 0;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: 0.15px;

  color: #828282;
  span {
    color: var(--app-font-color-main);
  }
}
</style>