import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { Nullable } from '@/types';

const RANGECOND = 'RiskPostureAnalysis';

export interface RiskPostureAnalysisItem {
  MaliciousTrafficCountBest: number;
  HighTrafficCountBest: number;
  StolenCredentialsCountBest: number;
  ReconCountWorst: number;
  OverallTrendCountWorst: number;
  MaliciousTrendCountBest: number;
  MaliciousTrafficCountWorst: number;
  TotalCount: number;
  MarketsCountWorst: number;
  StolenCredentialsCountWorst: number;
  MarketsCountBest: number;
  HighTrafficCountWorst: number;
  ReconCountBest: number;
  MaliciousTrendCountWorst: number;
  OverallTrendCountBest: number;
  ForumsCountWorst: number;
  ForumsCountBest: number;
}

@Module({ dynamic: true, store, name: 'riskPostureAnalysis' })
class RiskPostureAnalysis extends VuexModule {
  private data: Nullable<RiskPostureAnalysisItem> = null;

  @Action({ commit: 'setRiskPostureAnalysisData' })
  public fetchRiskPostureAnalysisData() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND)
      .then((data) => {
        if (data.length > 0) {
          return data[0];
        } else {
          throw new Error(RANGECOND + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  @Mutation
  public setRiskPostureAnalysisData(data: any) {
    try {
      const item: RiskPostureAnalysisItem = {
        MaliciousTrafficCountBest: Number(data['MaliciousTrafficCountBest']),
        HighTrafficCountBest: Number(data['HighTrafficCountBest']),
        StolenCredentialsCountBest: Number(data['StolenCredentialsCountBest']),
        ReconCountWorst: Number(data['ReconCountWorst']),
        OverallTrendCountWorst: Number(data['OverallTrendCountWorst']),
        MaliciousTrendCountBest: Number(data['MaliciousTrendCountBest']),
        MaliciousTrafficCountWorst: Number(data['MaliciousTrafficCountWorst']),
        TotalCount: Number(data['TotalCount']),
        MarketsCountWorst: Number(data['MarketsCountWorst']),
        StolenCredentialsCountWorst: Number(data['StolenCredentialsCountWorst']),
        MarketsCountBest: Number(data['MarketsCountBest']),
        HighTrafficCountWorst: Number(data['HighTrafficCountWorst']),
        ReconCountBest: Number(data['ReconCountBest']),
        MaliciousTrendCountWorst: Number(data['MaliciousTrendCountWorst']),
        OverallTrendCountBest: Number(data['OverallTrendCountBest']),
        ForumsCountWorst: Number(data['ForumsCountWorst']),
        ForumsCountBest: Number(data['ForumsCountBest']),
      };
      this.data = { ...item };
    } catch {
      console.error('failed to parse RiskPostureAnalysis data');
      this.data = null;
    }
  }

  get item() {
    return this.data;
  }
}

export const RiskPostureAnalysisModule = getModule(RiskPostureAnalysis);
