import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { api } from '../company';

// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
const { apiGateWayUrl } = awsConfig;

@Module
class FiDataModule extends VuexModule {
  private fidata: any = {};

  get companyFiData() {
    return this.fidata;
  }

  get companyFiDataHighlights() {
    return this.fidata.Highlights;
  }

  get companyFiDataFinancials() {
    return this.fidata.Financials;
  }

  get currencySymbol() {
    return this.fidata.General.CurrencySymbol;
  }

  @Mutation
  public setCompanyFiData(data: any) {
    this.fidata = data;
  }

  @Action
  public async fetchFiData(companyID: string) {
    store.commit('setCompanyFiData', {});

    const codeExchage = store.getters.companyEod;

    if (!codeExchage) {
      console.warn('EODlist has no data for: ', companyID);
      store.commit('setCompanyFiData', {});
      return;
    }

    const cognitoToken = store.getters.sessionToken;

    return api({
      url: `${apiGateWayUrl}fiData/financial?codeExchange=${codeExchage.code}.${codeExchage.exchange}`,
      method: 'get',
      headers: {
        Authorization: cognitoToken,
      },
    })
      .then(async (response) => {
        const result = response.data;
        if (result.success) {
          store.commit('setCompanyFiData', result.data);
        } else {
          throw new Error(`AWS  /fiData/financial success = false`);
        }
      })
      .catch(() => {
        console.error(`load fidata failed`);
        store.commit('setCompanyFiData', {});
      });
  }

  @Action
  public async fetchSectorPeersFiDataRowByTicker(ticker: string) {
    const cognitoToken = store.getters.sessionToken;
    return api({
      url: `${apiGateWayUrl}fiData/financial?codeExchange=${ticker}`,
      method: 'get',
      headers: {
        Authorization: cognitoToken,
      },
    })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          return {
            ticker: ticker,
            data: result.data,
          };
        }
        throw new Error(`ticker = ${ticker} ; load fidata success false`);
      })
      .catch((err) => {
        console.error(`load fidata failed`, err);
        return false;
      });
  }
}
export default FiDataModule;
