<template>
  <div class="text-field__container">
    <label v-if="label" :for="name">
      {{ label }}
    </label>
    <p v-if="caption" class="caption">{{ caption }}</p>
    <div class="field">
      <input
        :id="name"
        v-model="value"
        :type="type"
        :placeholder="placeholder"
        :name="name"
        required
        :disabled="disabled"
      />
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator';

@Component
export default class TextField extends Vue {
  @Prop() type!: 'password' | 'text' | 'number';
  @Prop() name!: string;
  @Prop() label!: string;
  @Prop() caption!: string | undefined;
  @Prop() disabled!: boolean | undefined;
  @Prop() placeholder!: string | undefined;
  @Prop() error!: string | undefined;
  @ModelSync('change', 'valueName', { type: String }) value!: string;
}
</script>

<style lang="scss" scoped>
.text-field__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  label {
    color: #000;
    font-family: Commissioner;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  input {
    display: flex;
    width: 100%;
    padding: 16px;
    color: #000;
    font-family: 'JetBrains Mono';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #f5f5f5;
      color: #00000066;
    }

    &::placeholder {
      text-transform: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  .caption {
    margin: 0;
    color: #00000066;
    font-family: Commissioner;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.field {
  width: 100%;
}

.error {
  margin: 0;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #ff0000;
}
</style>
