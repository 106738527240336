const config = {
  appName: 'intangic',
  tableName: process.env.VUE_APP_TABLE,
  identityPoolID: process.env.VUE_APP_IDENTITY_POOL_ID,
  poolData: {
    UserPoolId: process.env.VUE_APP_USER_POOL_ID,
    ClientId: process.env.VUE_APP_CLIENT_ID,
    region: process.env.VUE_APP_REGION,
  },
  baseUrl: process.env.VUE_APP_BASE_URL,
  apiGateWayUrl: process.env.VUE_APP_APIGW_URL,
  severityApiUrl: process.env.VUE_APP_SEVERITY_APIGW_URL,
  restorePasswordApiUrl: process.env.VUE_APP_RESTORE_PASSWORD_URL,
};

module.exports = config;
