<template>
  <router-link class="navbtn" :to="path" :class="{ selected: isSelected }">
    {{ title }}
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HeaderMenuItem extends Vue {
  @Prop() private pageName!: string;
  @Prop() private path!: string;
  @Prop() private title!: string;

  get isSelected(): boolean {
    const find = this.$route.path.indexOf(this.path);
    if (find === -1) return false;
    return true;
  }
}
</script>

<style scoped lang="css">
.navbtn {
  margin-right: 17px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.09em;
  text-transform: uppercase;

  color: #ffffff;
  opacity: 0.2;

  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
  text-decoration: none;

  min-width: 0;
}

.selected {
  opacity: 0.8;
}
</style>