import { Amplify } from 'aws-amplify';
// @ts-ignore
import awsConfig from '../environments/awsconfig.js';

export const configureAmplify = () =>
  Amplify.configure({
    Auth: {
      Cognito: {
        identityPoolId: awsConfig.identityPoolID,
        userPoolId: awsConfig.poolData.UserPoolId,
        userPoolClientId: awsConfig.poolData.ClientId,
      },
    },
  });
