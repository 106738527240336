<template>
  <div class="container">
    <MenuItem
      v-for="(item, index) in menuData"
      :key="index"
      :title="item.meta.title"
      :page-name="item.name"
      :path="item.path"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import MenuItem from './HeaderMenuItem.vue';

@Component({
  components: {
    MenuItem,
  },
})
export default class HeaderMenu extends Vue {
  get menuData() {
    return [];
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.container {
  margin-left: 28px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
