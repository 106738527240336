<template>
  <div class="company-page__container">
    <Preloader v-if="!dataIsLoaded" />

    <template v-else>
      <Header />
      <CompanyMenu />
      <keep-alive>
        <router-view @toggle-sidebar="$emit('toggle-sidebar')"></router-view>
      </keep-alive>
      <Footer @toggle-sidebar="$emit('toggle-sidebar')" />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Header from '../Header.vue';
import Footer from '../Footer.vue';
import Preloader from '@/components/LoaderPage.vue';
import CompanyMenu from './CompanyMenu.vue';
import Alert from '@/components/Alert.vue';
import { EarlyWarningModule } from '@/store/modules/company-tabs/early-warning';
import { BreachProbabilityModule } from '@/store/modules/company-tabs/breach-probability';
import { LossSeverityModule } from '@/store/modules/company-tabs/loss-severity';
import { RiskPostureAnalysisModule } from '@/store/modules/company-tabs/risk-posture-analysis';
import { PeerBenchmarkingModule } from '@/store/modules/company-tabs/peer-benchmarking';
import { AttackStageModule } from '@/store/modules/company-tabs/attack-stage';
import { BreachRatioModule } from '@/store/modules/company-tabs/breach-ratio';
import { AvgActivityModule } from '@/store/modules/company-tabs/avg-activity';

@Component({
  components: {
    Preloader,
    CompanyMenu,
    Alert,
    Header,
    Footer,
  },
})
export default class CompanyPage extends Vue {
  dataIsLoaded: boolean = false;

  mounted() {
    this.$store.dispatch('loadInitialData', 'customer');
    this.loadData(); // TODO: this function is quite slow
  }

  async loadData() {
    this.dataIsLoaded = false;

    const id: string = this.$route.params.companyId;

    if (id) {
      await this.$store.dispatch('loadCompanyTabsData', id);

      await Promise.allSettled([
        EarlyWarningModule.fetchEarlyWarningData(),
        BreachProbabilityModule.fetchCompanyBreachProbabilityCurrent(),
        LossSeverityModule.fetchLossSeverityPlots(),
        RiskPostureAnalysisModule.fetchRiskPostureAnalysisData(),
        PeerBenchmarkingModule.fetchPeerBenchmarkingTableData(),
        PeerBenchmarkingModule.fetchPeerBenchmarkingListData(),
        AttackStageModule.fetchAttackStageData(),
        BreachRatioModule.fetchCompanyBreachRatio(),
        AvgActivityModule.fetchAvgActivityData(),
      ]);

      document.title = this.$route.meta?.getTitle(this.$route.meta.title);
    } else {
      console.warn('company ID is undefined');
    }

    this.dataIsLoaded = true;
  }
}
</script>

<style scoped lang="scss">
.company-page__container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title__container {
  box-sizing: border-box;
  width: 100%;
  height: 156px;
  background: var(--app-background-color-light);
  padding: 39px var(--page-horizontal-padding) 30px;
  min-height: 156px;
}

.title__name {
  margin: 0;
  font-family: Commissioner;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.03em;
}

.toggle-btn {
  font-family: Commissioner;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--app-font-color-main);
  box-shadow: 0px 1px 0px var(--app-font-color-main);
  width: max-content;
  cursor: pointer;
  &:active {
    translate: 0px -1px;
  }
  &:hover {
    opacity: 0.7;
  }
}

.footer {
  padding: 54px var(--page-horizontal-padding) 10px;
  font-family: Commissioner;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
}
</style>
