<template>
  <div id="id01" class="login-page">
    <Header />
    <div class="login-page__container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Header from '../customer-pages/Header.vue';

@Component({ components: { Header } })
export default class LoginPage extends Vue {
  created() {
    this.$store.commit('setTheme', 'light');
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* The Modal (background) */
.login-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--app-background-color-light);
}

.login-page__container {
  box-sizing: border-box;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--app-background-color-light);
}
</style>