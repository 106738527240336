<template>
  <div>
    <div class="sidebar-toggle-button-container" @click="toggle()">
      <slot name="button">
        <button>open</button>
      </slot>
    </div>

    <div class="sidebar" tabindex="0" @keydown.esc="toggle(false)">
      <div class="sidebar-backdrop" :class="{ opened: isOpened }" @click="toggle(false)"></div>
      <transition name="slide">
        <div v-if="isOpened" class="sidebar-panel">
          <img class="close-btn" src="../assets/X.svg" @click="toggle(false)" />
          <slot>sidebar content</slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SidebarPopup extends Vue {
  isOpened: boolean = false;

  toggle(withValue?: boolean) {
    if (withValue !== undefined) {
      this.isOpened = withValue;
    } else {
      this.isOpened = !this.isOpened;
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  top: 0;
  left: 0;
  position: absolute;
}

.sidebar-backdrop {
  background-color: #000;
  width: 0px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  cursor: pointer;
  transition: opacity 200ms;
  opacity: 0;
  &.opened {
    opacity: 0.1;
    width: 100vw;
  }
}

.sidebar-panel {
  overflow-y: scroll;
  background-color: var(--app-background-color);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 1000;
}

.sidebar-toggle-button-container {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.close-btn {
  position: absolute;
  top: 26px;
  right: 29px;
  cursor: pointer;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(200%);
  transition: all 150ms ease-in 0s;
}
</style>
