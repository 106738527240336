<template>
  <div class="company-info__container">
    <h2 class="company-info__title">{{ title }}</h2>
    <div v-for="(row, index) in tableData" :key="index" class="company-info__table-row">
      <p>{{ row.title }}</p>
      <p>{{ row.value }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { CompanyProfile } from '@/types';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CompanyInfo extends Vue {
  @Prop() title!: string;

  get tableData() {
    const table: Array<{ title: string; value: string }> = [
      { title: 'MIC:', value: this.profile.MIC },
      { title: 'Ticker:', value: this.profile.CodeEod },
      { title: 'ISIN:', value: this.profile.ISIN },
      { title: 'Primary Exchange:', value: this.profile.ExchangeEod },
      { title: 'Benchmark', value: this.profile.Benchmark },
      {
        title: '# of Subsidiaries',
        value: this.profile.EstimatedSubsidiariesNumber || this.profile.NumberOfSubs || '',
      },
    ];
    return table;
  }

  get profile() {
    return this.$store.getters.companyProfile as CompanyProfile;
  }
}
</script>

<style scoped lang="scss">
.company-info__container {
  width: 100%;
  padding: 88px 43px 0;
}

.company-info__title {
  margin: 0 0 48px;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.01em;
  min-width: 476px;
}

.company-info__table-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 4px;
  border-bottom: 1px solid #0000001a;
  align-items: center;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  p {
    margin: 0;
  }
}
</style>
