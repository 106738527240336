import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { Nullable } from '@/types';

const RANGECOND_table = 'PeerBenchmarking#table';
const RANGECOND_list = 'PeerBenchmarking#list';

export type RiskGroup =
  | 'Low Risk'
  | 'Medium Risk'
  | 'Medium-High Risk'
  | 'High Risk'
  | 'Highest Risk';

export interface PeerBenchmarkingTableItem {
  MediumHighRiskBreachProbability: number;
  RiskGroupCurrent: RiskGroup;
  MediumRiskBreachProbability: number;
  LowRiskBreachProbability: number;
  Rank: number;
  HighestRiskBreachProbability: number;
  RiskGroup12MonthsAgo: RiskGroup;
  RankGroup: 'Best 25%' | 'Middle 50%' | 'Worst 25%';
  CompanyName: string;
  HighRiskBreachProbability: number;
}

export interface PeerBenchmarkingListItem {
  CompanyName: string;
}

@Module({ dynamic: true, store, name: 'peerBenchmarking' })
class PeerBenchmarking extends VuexModule {
  private data_table: Nullable<PeerBenchmarkingTableItem[]> = null;
  private data_list: Nullable<PeerBenchmarkingListItem[]> = null;

  @Action({ commit: 'setPeerBenchmarkingTableData' })
  public fetchPeerBenchmarkingTableData() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_table)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND_table + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  @Mutation
  public setPeerBenchmarkingTableData(data: any) {
    try {
      if (!Array.isArray(data)) throw new Error('setPeerBenchmarkingTableData: data is not array');
      const items: PeerBenchmarkingTableItem[] = data.map((el) => {
        const item: PeerBenchmarkingTableItem = {
          MediumHighRiskBreachProbability: Number(el['MediumHighRiskBreachProbability']),
          RiskGroupCurrent: el['RiskGroupCurrent'],
          MediumRiskBreachProbability: Number(el['MediumRiskBreachProbability']),
          LowRiskBreachProbability: Number(el['LowRiskBreachProbability']),
          Rank: Number(el['Rank']),
          HighestRiskBreachProbability: Number(el['HighestRiskBreachProbability']),
          RiskGroup12MonthsAgo: el['RiskGroup12MonthsAgo'],
          RankGroup: el['RankGroup'],
          CompanyName: String(el['CompanyName']),
          HighRiskBreachProbability: Number(el['HighRiskBreachProbability']),
        };

        return item;
      });

      this.data_table = [...items];
    } catch (error) {
      console.error('failed to parse PeerBenchmarkingTable data: ', error);
      this.data_table = null;
    }
  }

  @Action({ commit: 'setPeerBenchmarkingListData' })
  public fetchPeerBenchmarkingListData() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_list)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND_list + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  @Mutation
  public setPeerBenchmarkingListData(data: any) {
    try {
      if (!Array.isArray(data)) throw new Error('setPeerBenchmarkingListData: data is not array');
      const items: PeerBenchmarkingListItem[] = data.map((el) => {
        const item: PeerBenchmarkingListItem = {
          CompanyName: String(el['CompanyName']),
        };
        return item;
      });

      this.data_list = [...items];
    } catch (error) {
      console.error('failed to parse PeerBenchmarkingTable data: ', error);
      this.data_list = null;
    }
  }

  get tableData() {
    return this.data_table?.sort((a, b) => a.Rank - b.Rank);
  }

  get currentItem() {
    return this.data_table?.find(
      ({ CompanyName }) => CompanyName === store.getters.companyFullName
    );
  }

  get peerCompanyList() {
    return this.data_list?.map(({ CompanyName }) => CompanyName);
  }
}

export const PeerBenchmarkingModule = getModule(PeerBenchmarking);
