import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { queryCompanyScaledCyber } from '../../../api-rosie/company';
import { querySectorPeers } from '@/api-rosie/company/sector-peers';
import { Company, CyFiStatsRowMeta } from '@/types';
import { api } from '../company';

// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
const { apiGateWayUrl } = awsConfig;

@Module
class SectorPeersModule extends VuexModule {
  private sectorPeersArr: { row: CyFiStatsRowMeta; companyName: string }[] = [];
  private peersIdList: string[] = [];
  private sectorPeersChartsData: any[] = [];

  get companySectorPeers() {
    const result = this.sectorPeersArr.map((el: any) => {
      return {
        companyName: el.companyName,
        ...el.row,
        highlights:
          el.highlights &&
          el.highlights.data &&
          el.highlights.data.Highlights &&
          el.highlights.data.General
            ? convertHighlights(el.highlights.data.Highlights, {
                symbol: el.highlights.data.General.CurrencySymbol,
                code: el.highlights.data.General.CurrencyCode,
              })
            : null,
      };
    });
    return result;
  }

  get companySectorPeersChartsData() {
    return this.sectorPeersChartsData;
  }

  @Mutation
  public setPeersIdList(idList: string[]) {
    this.peersIdList = idList;
    // this.peersIdList.push(store.getters.companyInfo.companyID);
  }

  @Mutation
  public setSectorPeers(list: any[]) {
    this.sectorPeersArr = list;
  }

  @Action
  public async loadSectorPeers(id: string) {
    return querySectorPeers(id)
      .then(async (data) => {
        const list = data.map((el: { row: any; companyName: string }) => el.row.CompanyID);
        const tickerList = data.map((el: { row: any; companyName: string }) => el.row.Ticker);
        // Ticker
        store.commit('setPeersIdList', list);

        const fiList = await Promise.allSettled(
          tickerList.map((ticker) => {
            return store.dispatch('fetchSectorPeersFiDataRowByTicker', ticker);
          })
        );

        const fullData = data.map((el) => {
          const highlights = fiList.find((f) => {
            if (f.status === 'fulfilled') return f.value.ticker === el.row.Ticker;
            return false;
          });
          const resultData = {
            ...el,
            // @ts-ignore
            highlights: highlights ? highlights.value : null,
          };
          return resultData;
        });

        store.commit('setSectorPeers', fullData);
      })
      .catch(() => {
        console.error(`load SectorPeers failed`);
        store.commit('setSectorPeers', []);
      });
  }

  @Action
  public async fetchSectorPeersFiDataRow(id: string) {
    let codeExchange;
    try {
      codeExchange = await store.dispatch('loadEodByCompanyId', id);
    } catch {
      codeExchange = null;
    }

    if (!codeExchange) {
      console.warn('has no EOD data for: ', id);
      return false;
    }

    const cognitoToken = store.getters.sessionToken;

    return api({
      url: `${apiGateWayUrl}fiData/financial?codeExchange=${codeExchange.code}.${codeExchange.exchange}`,
      method: 'get',
      headers: {
        Authorization: cognitoToken,
      },
    })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          const cmp = store.getters.allCompanies.find((el: Company) => el.companyID == id);
          return {
            companyName: cmp ? cmp.companyName : '',
            companyID: id,
            data: result.data,
          };
        }
        throw new Error(`${id} load fidata success false`);
      })
      .catch((err) => {
        console.error(`load fidata failed`, err);
        return false;
      });
  }

  // charts data
  @Mutation
  public setSectorPeersChartsData(data: any[]) {
    const dataLength: number[] = data.map((cmp) => {
      return cmp.data.length;
    });
    const maxLength = Math.max(...dataLength);
    const result = data.map((cmp) => {
      const zeroObj = {
        ChTimestamp: '2020-01-01',
        Clients: 0,
        Exits: 0,
        Forums: 0,
        Grouping: '',
        Markets: 0,
        Osint: 0,
        Pastes: 0,
      };
      while (cmp.data.length !== maxLength) {
        cmp.data.unshift(zeroObj);
      }
      return cmp;
    });
    this.sectorPeersChartsData = result;
  }

  @Action
  public async fetchSectorPeersChartsData() {
    const badList: string[] = [];
    const idNameList = this.peersIdList
      .map((id: string) => {
        const cmp = store.getters.allCompanies.find((el: Company) => el.companyID == id);
        if (cmp) return { id: id, companyName: cmp.companyName };
        badList.push(id);
        return { id: id, companyName: null };
      })
      .filter((idName) => idName.companyName);

    if (badList.length !== 0)
      console.warn("Can't find companies in (CompanyProfile#current) by this ids: ", badList);

    return Promise.allSettled(
      idNameList.map(async (idName) => {
        const dataRow = await queryCompanyScaledCyber(idName.id)
          .then((data) => {
            return {
              companyName: idName.companyName,
              companyID: idName.id,
              data: data,
            };
          })
          .catch((err) => {
            console.error(err);
            return {};
          });
        return dataRow;
      })
    ).then((data) => {
      //@ts-ignore
      const fulfilledData = data
        .filter((row) => row.status === 'fulfilled')
        // @ts-ignore
        .map((row) => row.value);
      store.commit('setSectorPeersChartsData', fulfilledData);
    });
  }
}

// helpers
const formatCurrencyValue = (
  value: number,
  dec: number = 2,
  currencyFrom: { symbol: string; code: string },
  power?: number
): string => {
  const currencyTo = store.getters.currencyTo;
  const rates = store.getters.exchangeRates;
  const postfix: string[] = ['', 'k', 'mn', 'bn', 'tn'];
  let counter: number = 0;
  let curValue = (value * rates[currencyTo.code]) / rates[currencyFrom.code];
  if (power) {
    curValue = curValue * 10 ** power;
  }
  let result: string = '';
  if (value < 0) {
    result = result + '-';
  }

  while (Math.abs(curValue) > 1000 && counter < postfix.length - 1) {
    curValue = curValue / 1000;
    counter = ++counter;
  }

  result = numberSeparator(Math.abs(curValue).toFixed(dec));
  return result + ' ' + postfix[counter] + ' ' + currencyTo.symbol;
};

const numberSeparator = (number: string): string => {
  return number.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, `$& `);
};

const convertHighlights = (highlights: any, currencyFrom: { symbol: string; code: string }) => {
  return {
    'Market Capitalization': formatCurrencyValue(highlights.MarketCapitalization, 2, currencyFrom),
    EBITDA: formatCurrencyValue(highlights.EBITDA, 2, currencyFrom),
    PERatio: highlights.PERatio,
    PEGRatio: highlights.PEGRatio,
    'Wall Street Target Price': formatCurrencyValue(
      highlights.WallStreetTargetPrice,
      2,
      currencyFrom
    ),
    'Book Value': formatCurrencyValue(highlights.BookValue, 2, currencyFrom),
    'Dividend Share': formatCurrencyValue(highlights.DividendShare, 2, currencyFrom),
    'Dividend Yield': formatCurrencyValue(highlights.DividendYield, 2, currencyFrom),
    'Earnings Share': highlights.EarningsShare,
    'EPS Estimate Current Year': highlights.EPSEstimateCurrentYear,
    'EPS Estimate Next Year': highlights.EPSEstimateNextYear,
    'EPS Estimate Next Quarter': highlights.EPSEstimateNextQuarter,
    'EPS Estimate Current Quarter': highlights.EPSEstimateCurrentQuarter,
    'Profit Margin': highlights.ProfitMargin,
    'Operating Margin TTM': highlights.OperatingMarginTTM,
    'Return On Assets TTM': highlights.ReturnOnAssetsTTM,
    'Return On Equity TTM': highlights.ReturnOnEquityTTM,
    'Revenue TTM': formatCurrencyValue(highlights.RevenueTTM, 2, currencyFrom),
    'Revenue Per Share TTM': highlights.RevenuePerShareTTM,
    'Quarterly Revenue Growth YOY': highlights.QuarterlyRevenueGrowthYOY,
    'Gross Profit TTM': formatCurrencyValue(highlights.GrossProfitTTM, 2, currencyFrom),
    'Diluted Eps TTM': highlights.DilutedEpsTTM,
    'Quarterly Earnings Growth YOY': highlights.QuarterlyEarningsGrowthYOY,
  };
};

export { SectorPeersModule, convertHighlights };
