<template>
  <div class="register-page__container">
    <Register
      v-if="status === 'register_mfa'"
      :email="email"
      :token="token"
      :cid="cid"
      @on-success="onSuccessLogin"
    />
    <SetupTotp
      v-else-if="status === 'setup_totp'"
      :totp-uri="totpUri"
      @on-success="onSuccessConfirm"
    />
    <Preloader v-else />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Register from './Register.vue';
import SetupTotp from './SetupTotp.vue';
import { ConfirmSignInOutput, SignInOutput } from 'aws-amplify/auth';
import Preloader from '@/components/LoaderPage.vue';
// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
const { appName } = awsConfig;

@Component({ components: { Register, SetupTotp, Preloader } })
export default class RegisterPage extends Vue {
  status: 'register_mfa' | 'setup_totp' | 'loading' = 'register_mfa';
  totpUri: string = '';
  email = '';
  token = '';
  cid = '';

  created() {
    const { email, token, cid } = this.$route.query;
    if (typeof email === 'string' && typeof token === 'string' && typeof cid === 'string') {
      this.email = email;
      this.token = token;
      this.cid = cid;
    }
  }

  async onDone() {
    const redirectPath: string =
      this.$route.query.redirect && !Array.isArray(this.$route.query.redirect)
        ? this.$route.query.redirect
        : '/';
    this.status = 'loading';
    await this.$router.push(redirectPath);
  }

  onSuccessLogin(output: SignInOutput) {
    switch (output.nextStep.signInStep) {
      case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP': {
        const uri = output.nextStep.totpSetupDetails.getSetupUri(appName, this.email).href;
        this.totpUri = uri;
        this.status = 'setup_totp';
        break;
      }

      case 'DONE':
        this.onDone();
    }
  }

  onSuccessConfirm({ nextStep }: ConfirmSignInOutput) {
    switch (nextStep.signInStep) {
      case 'DONE':
        this.onDone();
    }
  }
}
</script>

<style scoped lang="scss">
.register-page__container {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
