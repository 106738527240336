import { AuthSession, AuthUser, fetchAuthSession } from 'aws-amplify/auth';
import { store } from '../index';
// @ts-ignore
import awsConfig from '../../environments/awsconfig.js';
const { restorePasswordApiUrl } = awsConfig;

import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';

const storage = window.localStorage;

@Module
class Session extends VuexModule {
  private session: AuthSession | undefined = undefined;
  private sessionUsername: string | null = null;
  private loginError: string = '';
  private remember: boolean = storage.getItem('remeberOnThisComputer') === 'true' ? true : false;

  @Mutation
  public setRemember(value: boolean) {
    this.remember = value;
    storage.setItem('remeberOnThisComputer', String(this.remember));
    console.debug('setRemember', this.remember);
  }

  get rememberOnLogin(): boolean {
    return this.remember;
  }

  @Action
  async updateSessionInfo(user: AuthUser) {
    store.commit('setUserName', user.username);

    const session = await fetchAuthSession();

    store.commit('setAuthSession', session);
    store.commit('setTheme', 'light'); // TODO: since there is only one, it should be set at startup
  }

  @Action
  public fetchRestorePassword(email: string) {
    return axios({
      url: restorePasswordApiUrl,
      method: 'post',
      data: { username: email },
    });
  }

  @Mutation
  public setUserName(username: string) {
    this.sessionUsername = username;
  }

  get username(): string | null {
    return this.sessionUsername;
  }

  @Mutation
  public setAuthSession(session: AuthSession | undefined) {
    this.session = session;
  }

  get sessionToken() {
    return this.session?.tokens?.idToken?.toString();
  }

  get sessionDecodedToken() {
    return this.session?.tokens?.idToken?.payload ?? {};
  }

  get isRestrictedUser(): boolean {
    return !!this.sessionDecodedToken['custom:companyID'];
  }

  get isAdminUser(): boolean {
    const groups = (this.sessionDecodedToken['cognito:groups'] || []) as string[];
    return groups.some((group: string) => /admin/i.test(group));
  }

  // get isAdvisoryUser(): boolean {
  //   const groups = (this.sessionDecodedToken['cognito:groups'] || []) as string[];
  //   return groups.some((group: string) => /advisory/i.test(group));
  // }

  // get isPolicyUser(): boolean {
  //   return this.isRestrictedUser && !this.isAdvisoryUser;
  // }

  get customerCompanyId() {
    if (this.isRestrictedUser) {
      return this.sessionDecodedToken['custom:companyID'];
    }
    return null;
  }

  @Mutation
  public setLoginError(error: string) {
    this.loginError = error;
  }

  get sessionLoginError(): string {
    return this.loginError;
  }

  @Mutation
  public setTheme(theme: string) {
    const htmlElement = document.documentElement;
    if (theme === '') {
      return htmlElement.removeAttribute('theme');
    }

    return htmlElement.setAttribute('theme', theme);
  }

  get theme() {
    return document.documentElement.getAttribute('theme');
  }
}
export default Session;
