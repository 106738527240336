<template>
  <div class="progress__container">
    <div class="container bar">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="step"
        :style="{ width: stepWidth }"
        :class="{ loaded: step === 'loaded' }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
  @Prop() private count!: number;
  @Prop() private loaded!: number;
  steps: string[] = [];

  created() {
    for (let i = 0; i < this.count; i++) {
      if (i < this.loaded) {
        this.steps.push('loaded');
      } else {
        this.steps.push('');
      }
    }
  }

  @Watch('loaded')
  onLoadedChanged() {
    this.steps = this.steps.map((_val, ind) => {
      return ind < this.loaded ? 'loaded' : '';
    });
  }

  get stepWidth(): string {
    return 100 / this.count + '%';
  }
}
</script>

<style scoped lang="scss">
.progress__container {
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 300px;
  height: 5px;
  border: 1px solid #383838;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  .step {
    max-width: 0px;
    height: 100%;
    background-color: var(--app-font-color-main);
    transition: 0.5s ease-in;
  }
  .loaded {
    max-width: 300px;
  }
}
</style>
