<template>
  <div class="root root_admin">
    <AdminTopBar v-if="isAdminUser" />
    <div>
      <ProgressBar v-if="!loadedOnce" :count="progress.count" :loaded="progress.loaded" />

      <router-view v-else-if="$route.params.companyId" :key="$route.params.companyId"></router-view>

      <div v-else class="empty__container">
        <p class="empty__text">
          You don't have any companies visited yet, so we suggest you start with a search or see how
          <router-link :to="'/Company/HWUPKR0MPOU8FGXBT394_lei/overview'">Apple</router-link>
          is doing.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AdminTopBar from '@/components/header/AdminTopBar.vue';
import ProgressBar from '@/components/ProgressBar.vue';

@Component({
  components: {
    AdminTopBar,
    ProgressBar,
  },
})
export default class RootPage extends Vue {
  created() {
    this.$store.dispatch('loadInitialData', 'admin');
  }

  get loadedOnce() {
    return this.$store.getters.initialDataIsLoaded;
  }

  get progress() {
    return this.$store.getters.initialDataProgress;
  }

  get isAdminUser(): boolean {
    return this.$store.getters.isAdminUser;
  }
}
</script>

<style scoped lang="scss">
@use '@/styles/header/header';

.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.empty__container {
  margin: 110px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.empty__text {
  width: 446px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;

  span {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
