import Vue from 'vue';
import App from './App.vue';
import { store } from './store';
import router from './router';

import WebFont from 'webfontloader';
import VueClipboard from 'vue-clipboard2';
// @ts-ignore
import ClickOutside from 'vue-click-outside';
import { configureAmplify } from './amplify';
import { fetchAuthSession } from 'aws-amplify/auth';

configureAmplify();

Vue.use(VueClipboard);
Vue.directive('click-outside', ClickOutside);

// fonts loader
WebFont.load({
  custom: {
    families: ['Transcript Pro', 'Akkurat Pro', 'Inter', 'JetBrains Mono'],
  },
  async active() {
    Vue.config.productionTip = false;
    try {
      await fetchAuthSession({ forceRefresh: true });
    } catch (e) {
      console.warn('Initial auth failed', e);
    }
    new Vue({
      store,
      router,
      render: (h) => h(App),
    }).$mount('#app');
  },
});
