<template>
  <router-view id="app"></router-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ToggleButton } from 'vue-js-toggle-button';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
// @ts-ignore
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';

Vue.use(Autocomplete);
Vue.component('ToggleButton', ToggleButton);
Vue.component('VSelect', vSelect);

import './styles/styles.scss';

@Component
export default class App extends Vue {}
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  width: 100%;
  color: var(--app-font-color-main);
}
</style>
