import { store } from '@/store';

const CustomerOverviewPageTimeline = () =>
  import(
    /* webpackChunkName: "customerPolicy" */ '@/pages/customer-pages/Company/CustomerOverviewPageTimeline.vue'
  );

const EarlyWarningPage = () =>
  import(
    /* webpackChunkName: "customerPolicy" */ '@/pages/customer-pages/Company/EarlyWarningPage/EarlyWarningPage.vue'
  );

const PeerBenchmarkingMock = () =>
  import(
    /* webpackChunkName: "customerPolicy_peerBenchmarkingMock" */ '@/pages/customer-pages/Company/PeerBenchmarkingMock/PeerBenchmarkingMock.vue'
  );

const NpvCalculatorPage = () =>
  import(
    /* webpackChunkName: "customerPolicy_peerBenchmarkingMock" */ '@/pages/customer-pages/Company/NpvCalculatorPage/NpvCalculatorPage.vue'
  );

export const getCustomerCompanyPageTitle = (pageTitle: string) => {
  return [pageTitle, store.getters.companyFullName].filter(Boolean).join(' - ');
};

export type CompanyRoutesNames = (typeof companyRoutes)[number]['name'];

export const companyRoutes = [
  {
    component: CustomerOverviewPageTimeline,
    path: 'overview',
    name: 'CustomerOverviewPage',
    meta: {
      companyRoute: true,
      title: 'Overview',
      menuTitle: 'Overview',
      getTitle: getCustomerCompanyPageTitle,
    },
  },

  {
    component: EarlyWarningPage,
    path: 'early_warning',
    name: 'EarlyWarning',
    meta: {
      companyRoute: true,
      title: 'Early Warning',
      menuTitle: 'Early Warning',
      getTitle: getCustomerCompanyPageTitle,
    },
  },

  {
    component: PeerBenchmarkingMock,
    path: 'peer_benchmarking',
    name: 'PolicyPeerBenchmarking',
    meta: {
      companyRoute: true,
      title: 'Peer Benchmarking',
      menuTitle: 'Peer Benchmarking',
      getTitle: getCustomerCompanyPageTitle,
    },
  },

  {
    component: NpvCalculatorPage,
    path: 'npv_calculator',
    name: 'NpvCalculatorPage',
    meta: {
      companyRoute: true,
      title: 'NPV Calculator',
      menuTitle: 'NPV Calculator',
      getTitle: getCustomerCompanyPageTitle,
    },
  },

  /*
  {
    component: ThirdPartyReportPage,
    path: 'third_party_report',
    name: 'ThirdPartyReport',
    meta: {
      companyRoute: true,
      title: 'Third party report',
      menuTitle: 'Third party report',
      getTitle: getCustomerCompanyPageTitle,
    },
  },
  */

  /*
  {
    component: MockPage,
    path: 'cy_fi_feasibility_report',
    name: 'PolicyCyFiFeasibilityReport',
    meta: {
      companyRoute: true,
      title: 'CyFi™ Feasibility Report',
      menuTitle: 'CyFi™ Feasibility Report',
      getTitle: getCustomerCompanyPageTitle,
    },
  },
  */

  /*
  {
    component: MockPage,
    path: 'board_report',
    name: 'PolicyBoardReport',
    meta: {
      companyRoute: true,
      title: 'Board Report',
      menuTitle: 'Board Report',
      getTitle: getCustomerCompanyPageTitle,
    },
  },
  */
] as const;
