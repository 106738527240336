<template>
  <Form title="Register" @on-submit="register">
    <AuthError />
    <TextField
      v-model="email"
      name="email"
      :label="'Email'"
      disabled
      :placeholder="'enter email'"
    />
    <TextField
      v-model="password"
      name="password"
      type="password"
      :label="'New password'"
      :disabled="isLoading"
      :caption="'Minimum length of 8 characters at least one number and one special character must be mixed case (i.e. lower- and uppercase)'"
      :error="passError"
    />
    <TextField
      v-model="confirmPassword"
      name="confirm"
      type="password"
      :label="'Confirm password'"
      :disabled="isLoading"
      :error="confirmError"
    />
    <RememberCheckBox />
    <SubmitButton :caption="'Create account'" :is-loading="isLoading" />
    <router-link to="/Login/userlogin" class="link">Sign in</router-link>
  </Form>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import AuthError from '../elements/AuthError.vue';
import Form from '../elements/Form.vue';
import RememberCheckBox from '../elements/RememberCheckBox.vue';
import SubmitButton from '../elements/SubmitButton.vue';
import TextField from '../elements/TextField.vue';
import { signIn, SignInOutput, signUp } from 'aws-amplify/auth';

@Component({ components: { Form, TextField, RememberCheckBox, SubmitButton, AuthError } })
export default class Register extends Vue {
  @Prop() email!: string;
  @Prop() token!: string;
  @Prop() cid!: string;
  password: string = '';
  confirmPassword: string = '';
  isLoading: boolean = false;
  passError: string = '';
  confirmError: string = '';

  @Watch('password')
  @Watch('confirmPassword')
  onPassChanged() {
    this.passError = '';
    this.confirmError = '';
  }

  validate(): boolean {
    this.passError = '';
    this.confirmError = '';

    if (this.password.length < 8) {
      this.passError = 'must be at least 8 characters long';
      return false;
    };

    if (this.password !== this.confirmPassword) {
      this.passError = '';
      this.confirmError = 'passwords do not match';
      return false;
    }

    return true;
  }

  async register() {
    this.$store.commit('setLoginError', '');
    if (!this.validate()) return;
    this.isLoading = true;
    signUp({
      username: this.email,
      password: this.password,
      options: {
        userAttributes: {
          email: this.email,
          'custom:companyID': this.cid,
        },
        validationData: {
          token: this.token,
        },
      },
    })
      .then(({ isSignUpComplete }) => {
        if (!isSignUpComplete) return;
        return signIn({
          username: this.email,
          password: this.password,
        }).then(this.onSuccess);
      })
      .catch((err) => {
        if (err.message) {
          this.$store.commit('setLoginError', err.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Emit()
  onSuccess(output: SignInOutput) {
    return output;
  }
}
</script>

<style lang="scss" scoped>
.link {
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #787878;
  margin: 0 auto;
}
</style>