import { store } from '../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { api } from './company';
// @ts-ignore
import awsConfig from '../../environments/awsconfig.js';
const { apiGateWayUrl } = awsConfig;

const INITIAL_CURRENCY_TABLE = [
  { code: 'EUR', symbol: '€', value: true },
  { code: 'USD', symbol: '$', value: false },
];

const INITIAL_RISK_OPTIONS = [
  { label: 'Sector Score', value: true, type: 'Risk Scores' },
  { label: 'Cy Scores', value: true, type: 'Risk Scores' },
  { label: 'FiMQs Scores', value: true, type: 'Risk Scores' },
];

const INITIAL_FIDATA_OPTIONS = [
  { label: 'Market Capitalization', value: true },
  { label: 'EBITDA', value: false },
  { label: 'PERatio', value: false },
  { label: 'PEGRatio', value: false },
  { label: 'Wall Street Target Price', value: false },
  { label: 'Book Value', value: false },
  { label: 'Dividend Share', value: false },
  { label: 'Dividend Yield', value: false },
  { label: 'Earnings Share', value: false },
  { label: 'EPS Estimate Current Year', value: false },
  { label: 'EPS Estimate Next Year', value: false },
  { label: 'EPS Estimate Next Quarter', value: false },
  { label: 'EPS Estimate Current Quarter', value: false },
  { label: 'Profit Margin', value: false },
  { label: 'Operating Margin TTM', value: false },
  { label: 'Return On Assets TTM', value: false },
  { label: 'Return On Equity TTM', value: false },
  { label: 'Revenue TTM', value: false },
  { label: 'Revenue Per Share TTM', value: false },
  { label: 'Quarterly Revenue Growth YOY', value: false },
  { label: 'Gross Profit TTM', value: false },
  { label: 'Diluted Eps TTM', value: false },
  { label: 'Quarterly Earnings Growth YOY', value: false },
];

const storage = window.localStorage;
const customDataStorageNames = {
  risk: 'rosie#customize-table-options_risk',
  fidata: 'rosie#customize-table-options_fidata',
};

@Module
class HelpersModule extends VuexModule {
  private exchangeTable: any = {};
  private base: string = 'EUR';
  private convertTable: { code: string; symbol: string; value: boolean }[] = JSON.parse(
    JSON.stringify(INITIAL_CURRENCY_TABLE)
  );
  private tableOptions: any[] = INITIAL_RISK_OPTIONS;
  private tableHighlightsOptions: any[] = INITIAL_FIDATA_OPTIONS;

  get riskScoresOptions() {
    return this.tableOptions.filter((el) => el.type === 'Risk Scores');
  }

  get fiDataTableOptions() {
    return this.tableHighlightsOptions;
  }

  get exchangeRates() {
    return this.exchangeTable;
  }

  get currencyTo() {
    return this.convertTable.find((item) => item.value === true);
  }

  get currencyTable() {
    return this.convertTable;
  }

  @Mutation
  public setTableOptionsItem(item: any) {
    const result = this.tableOptions.map((el) => {
      if (el.label === item.label) {
        el.value = !item.value;
      }
      return el;
    });
    store.commit('setTableOptions', result);
  }

  @Mutation
  public setHighlightsOptionsItem(item: any) {
    const result = this.tableHighlightsOptions.map((el) => {
      if (el.label === item.label) {
        el.value = !item.value;
      }
      return el;
    });
    store.commit('setTableOptionsHighlights', result);
  }

  @Mutation
  public setCurrencyItem(item: any) {
    const result = this.convertTable.map((el) => {
      if (el.code === item.code) {
        el.value = true;
      } else el.value = false;
      return el;
    });
    this.convertTable = result;
  }

  @Mutation
  public renewСonvertTable() {
    this.convertTable = JSON.parse(JSON.stringify(INITIAL_CURRENCY_TABLE));
  }

  @Mutation
  public appendСonvertTable(item: any) {
    this.convertTable = JSON.parse(JSON.stringify(INITIAL_CURRENCY_TABLE));
    const hasItem = this.convertTable.find((el) => el.code === item.code);
    if (!hasItem) {
      this.convertTable.push(item);
    }
  }

  @Mutation
  public setExchangeData(data: any) {
    this.base = data.base;
    this.exchangeTable = data.rates;
  }

  @Action
  public async fetchExchangeData() {
    const cognitoToken = store.getters.sessionToken;
    return api({
      url: `${apiGateWayUrl}exchangeTable/`,
      method: 'get',
      headers: {
        Authorization: cognitoToken,
      },
    })
      .then(async (response) => {
        const result = JSON.parse(JSON.stringify(response.data));
        store.commit('setExchangeData', result.data);
        return result;
      })
      .catch((error) => {
        console.error(`load exchange data failed`, error);
      });
  }

  @Mutation
  public setTableOptions(data: any[]) {
    this.tableOptions = data;
    storage.setItem(customDataStorageNames.risk, JSON.stringify(this.tableOptions));
  }

  @Mutation
  public setTableOptionsHighlights(data: any[]) {
    this.tableHighlightsOptions = data;
    storage.setItem(customDataStorageNames.fidata, JSON.stringify(this.tableHighlightsOptions));
  }

  @Action
  public initCustomizeTableOptions() {
    const storageRisk = storage.getItem(customDataStorageNames.risk);
    const storageFidata = storage.getItem(customDataStorageNames.fidata);
    if (storageRisk) {
      store.commit('setTableOptions', JSON.parse(storageRisk));
    } else {
      store.commit('setTableOptions', INITIAL_RISK_OPTIONS);
    }
    if (storageFidata) {
      store.commit('setTableOptionsHighlights', JSON.parse(storageFidata));
    } else {
      store.commit('setTableOptionsHighlights', INITIAL_FIDATA_OPTIONS);
    }
  }
}
export default HelpersModule;
