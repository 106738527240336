import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { queryCompanyRaeAlert } from '@/api-rosie/company/rae-alert';

export type RaeAlertRow = {
  timeStamp: Date;
  companyID: string;
  RaeRisk: 'High' | 'Medium' | 'Low';
};

@Module
class RaeAlertModule extends VuexModule {
  private dataList: RaeAlertRow[] = [];

  @Action
  public async fetchRaeAlert(id: string) {
    return queryCompanyRaeAlert(id)
      .then((data) => {
        store.commit('setRaeAlertData', data);
      })
      .catch(() => {
        console.error(`🚫 load rae-alert failed`);
        store.commit('setCyData', []);
      });
  }

  @Mutation
  public setRaeAlertData(items: RaeAlertRow[]) {
    this.dataList.splice(0, this.dataList.length, ...items);
  }

  get companyRaeAlertList(): RaeAlertRow[] {
    const list: RaeAlertRow[] = this.dataList;
    return [...list.reverse()];
  }
}
export default RaeAlertModule;
