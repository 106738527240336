import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { queryCompanyCorporateEvents } from '@/api-rosie/company/corporate-events';

@Module
class CorporateEventsModule extends VuexModule {
  private corporateEventsList: any[] = [];

  get companyCorporateEvents() {
    return this.corporateEventsList;
  }

  @Mutation
  public setCompanyCorporateEvents(list: any[]) {
    this.corporateEventsList = list;
  }

  @Action
  public async queryCorporateEvents(id: string) {
    return queryCompanyCorporateEvents(id)
      .then((data) => {
        store.commit('setCompanyCorporateEvents', data);
      })
      .catch(() => {
        store.commit('setCompanyCorporateEvents', []);
        console.error(`load corporateEvents failed`);
      })
      .finally(() => {
        // store.commit('setSubsidiariesLoading', false);
      });
  }
}
export default CorporateEventsModule;
