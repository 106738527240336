import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { queryCompanyCyData } from '../../../api-rosie/company/cy-data';
import { CyRow } from '@/types';
import { RaeAlertRow } from '@/store/modules/company-tabs/rae-alert';
export type CyDataWithAlerts = CyRow & RaeAlertRow;

@Module
class CyDataModule extends VuexModule {
  private cydataCommon: CyRow[] = [];

  get companyCyData(): CyRow[] {
    const companyData: CyRow[] = this.cydataCommon;
    return [...companyData.reverse()];
  }

  get cyDataListWithAlerts(): CyDataWithAlerts[] {
    const result: CyDataWithAlerts[] = [];
    const alerts = store.getters.companyRaeAlertList;
    let alert_counter = 0;
    const cyList = store.getters.companyCyData;
    for (const cyRow of cyList) {
      if (alert_counter >= alerts.length) {
        result.push(cyRow);
        continue;
      }
      const t1 = cyRow.timeStamp.getTime();
      let t2 = alerts[alert_counter].timeStamp.getTime();
      while (t1 <= t2 && alert_counter < alerts.length) {
        t2 = alerts[alert_counter].timeStamp.getTime();
        if (t1 === t2) {
          break;
        }
        alert_counter = alert_counter + 1;
      }
      if (t1 === t2) {
        result.push({ ...cyRow, ...alerts[alert_counter] });
      } else {
        result.push(cyRow);
      }
    }
    return result;
  }

  get currentRaeAlert(): CyDataWithAlerts | null {
    const currentCyRow = this.cyDataListWithAlerts[0];
    if (currentCyRow && currentCyRow.RaeRisk) {
      return currentCyRow;
    }
    return null;
  }

  @Mutation
  public setCyData(data: CyRow[]) {
    this.cydataCommon.splice(0, this.cydataCommon.length, ...data);
  }

  @Action
  public async fetchCyData(id: string) {
    return queryCompanyCyData(id)
      .then((data) => {
        store.commit('setCyData', data);
      })
      .catch(() => {
        console.error(`load cydata failed`);
        store.commit('setCyData', []);
      });
  }
}
export default CyDataModule;
