export function formatCurrencyValue(value: number, dec: number = 2, power?: number): string {
  const postfix: string[] = ['', 'k', 'mn', 'bn', 'tn'];
  let counter: number = 0;
  let curValue = value;
  if (power) {
    curValue = curValue * 10 ** power;
  }
  let result: string = '';

  if (value < 0) {
    result = result + '-';
  }

  while (Math.abs(curValue) > 1000 && counter < postfix.length - 1) {
    curValue = curValue / 1000;
    counter = ++counter;
  }

  result = result + '$' + Math.abs(curValue).toFixed(dec) + postfix[counter];

  return result;
}

export function floatToPercent(value: number, dec: number = 2): string {
  let result: string = '';
  result = (value * 100).toFixed(dec) + '%';
  return result;
}

export function calcWeigthedField(
  source: any[],
  valFieldName: string,
  weightFieldName: string = 'portfolioWeight'
) {
  let sumWeights = 0;
  let weightedSum = 0;
  source.forEach((s) => {
    sumWeights += +s[weightFieldName];
    weightedSum += (+s[valFieldName] || 0) * s[weightFieldName];
  });
  return weightedSum / sumWeights || 0;
}

export function countWeightedValue(weights: number[], values: number[]): number {
  let sumWeights: number = 0;
  if (!weights.length) {
    return 0;
  }
  let result = weights.reduce((curSum: number, w: number, i: number) => {
    sumWeights += w;
    return curSum + w * values[i];
  }, 0);
  result /= sumWeights;
  return result;
}

export function translateScore(score: number): number {
  if (score >= 1 && score <= 1.66) {
    return 1;
  }
  if (score > 1.66 && score <= 2.5) {
    return 2;
  }
  if (score > 2.5 && score <= 3.5) {
    return 3;
  }
  if (score > 3.5 && score <= 4.33) {
    return 4;
  }
  if (score > 4.33 && score <= 5) {
    return 5;
  }
  /*
  1-1.66 = 1 star
  1.66-2.5 = 2 star
  2.51-3.49 = 3 star
  3.50-4.33 = 4 star
  4.34-5 = 5 star
*/
  return 0;
}

export const parseCbhDate = (date: string) => (date !== '-' ? new Date(date) : undefined);

export const getCbhDate = (date: Date) =>
  date
    .toLocaleString('us', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('.')
    .reverse()
    .join('-');

export const formatDateUs = (date: Date) =>
  date.toLocaleString('us', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

export type ChTimeItem = {
  timestamp: number;
  year: number;
  month: number;
  day: number;
  ChTimestamp: string;
  displayDate: string;
};

export const getCbhTimeItem = (str: string): ChTimeItem => {
  const [year, month, day] = str.split('-').map(Number);
  const timestamp = Date.UTC(year, month - 1, day);
  const displayDate = [('0' + day).slice(-2), ('0' + month).slice(-2), year].join('.');
  return {
    timestamp,
    ChTimestamp: str,
    year,
    month,
    day,
    displayDate,
  };
};

export const debounce = (callback: () => void, wait: number) => {
  let timeoutId: undefined | number = undefined;
  return () => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback();
    }, wait);
  };
};
