import Vue from 'vue';
import VueRouter from 'vue-router';

// store
import { store } from '../store/index';

// Pages
import LoginPage from '../pages/LoginPage/index.vue';
import UserLoginPage from '../pages/LoginPage/userlogin/index.vue';
import ForgotPasswordPage from '../pages/LoginPage/forgot/index.vue';
import RootPage from '@/pages/admin-pages/index.vue';
import CustomerCompanyPage from '@/pages/customer-pages/Company/index.vue';
import RegisterPage from '@/pages/LoginPage/register/index.vue';

import { AuthUser, getCurrentUser } from 'aws-amplify/auth';
import { companyRoutes } from './customer-routes/routes';

Vue.use(VueRouter);

const loginRoutes = [
  {
    component: UserLoginPage,
    path: '/Login/userlogin',
    name: 'UserLoginPage',
    meta: {
      getTitle: () => 'Login - Intangic CyFi™ Platform',
    },
  },
  {
    component: ForgotPasswordPage,
    path: '/Login/forgot',
    name: 'ForgotPasswordPage',
    meta: {
      getTitle: () => 'Forgot password - Intangic CyFi™ Platform',
    },
  },
  {
    component: RegisterPage,
    path: '/Login/register',
    name: 'RegisterPage',
    meta: {
      getTitle: () => 'Register - Intangic CyFi™ Platform',
    },
  },
] as const;

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: RootPage,
      children: [
        {
          component: CustomerCompanyPage,
          path: '/Company/:companyId?',
          children: [...companyRoutes],
        },
      ],
    },
    {
      path: '/Login',
      name: 'LoginPage',
      component: LoginPage,
      children: [...loginRoutes],
    },
  ],
});

const beforeAuthRoutes: Array<(typeof loginRoutes)[number]['path']> = [
  '/Login/userlogin',
  '/Login/forgot',
  '/Login/register',
];

router.beforeEach(async (to, from, next) => {
  try {
    // ignore this redirection, since occurs before the auto-redirect, and causes error
    if (from.path === '/Login/userlogin' && to.path === '/') next();

    // retrieve authenticated user, if none, returns error
    const user: AuthUser = await getCurrentUser();

    // need to wait for the store to be settled
    await store.dispatch('updateSessionInfo', user);

    // in case of login successful with a non-admin user, auto-redirect to its initial dashboard
    if (to.path === '/' && !to.params.companyId && store.getters.customerCompanyId) {
      next(`/Company/${store.getters.customerCompanyId}/overview`);
    } else {
      next();
    }
  } catch {
    // in case user is not signed in, redirect to the login page, unless for the following routes:
    if ((beforeAuthRoutes as string[]).includes(to.path)) {
      next();
    } else {
      next({ path: '/Login/userlogin' });
    }
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    let title = 'Intangic CyFi™ Platform';
    if (to.meta?.getTitle) {
      title = to.meta.getTitle(to.meta.title);
    }
    document.title = title;
  });
});

export default router;
