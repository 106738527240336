<template>
  <p v-if="loginError" class="error">{{ loginError }}</p>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AuthError extends Vue {
  get loginError(): string {
    return this.$store.getters.sessionLoginError;
  }
}
</script>

<style lang="scss" scoped>
.error {
  margin: 0;
  width: 100%;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ff0000;
  box-sizing: border-box;
  height: 52px;
  background: rgba(255, 0, 0, 0.04);
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  padding: 0 10px;
}
</style>
